// Utilities & Hooks
import { useOnlineApplicationGetSpecificForm } from "../../api/OnlineApplication/OnlineApplication";
import { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { GOOGLE_RECAPTCHA_KEY } from "../../config/config";
import useErrorReporting from "../../hooks/useErrorReporting";
import fetchHandlerUpload from "../../api/fetchHandlerUpload";
import handleFormikScrollToFirstError from "../../utilities/forms/formik";
import * as Yup from "yup";

// Components
import { ErrorMessage, Field, Form, Formik, FormikValues } from "formik";
import Button from "../../components/Button/Button";
import Loader from "../../components/Loader/Loader";
import ReCAPTCHA from "react-google-recaptcha";
import TermsOfService from "../../components/OnlineApplications/TermsOfService";
import OnlineApplicationFooter from "../../components/OnlineApplications/OnlnieApplicationFooter";
import OnlineApplicationArraySection from "../../components/OnlineApplications/OnlineApplicationArraySection";
import Alert from "../../components/Alert/Alert";

// Interfaces
import { OnlineApplicationFormInitialValues } from "./interfaces";

// Assets
import ComodoSecureLogo from "../../assets/images/comodo_secure_white.png";

// Form Builder
import { handleFormArraySectionsFieldValidationSchemas } from "./form-builder/handleFormArraySectionsFieldValidationSchemas";
import { handleFormFieldComponentMapping } from "./form-builder/utilities";
import { handleFormFieldComponentProps } from "./form-builder/handleFormFieldComponentProps";
import { handleFormFieldSizes } from "./form-builder/handleFormFieldSizes";
import { handleFormFieldsInitialValues } from "./form-builder/handleFormFieldsInitialValues";
import { handleRegularFormFieldsValidation } from "./form-builder/handleRegularFormFieldsValidation";
import OnlineApplicationDisabledClient from "../../components/OnlineApplications/OnlineApplicationDisabledClient";

const OnlineApplication = () => {
  const navigate = useNavigate();
  const { slug } = useParams();
  const errorReporting = useErrorReporting();

  // If the user went backwards using the "Previous Page" button
  // reload the page, so the Google ReCAPTCHA is reinitialized
  window.onpopstate = () => {
    window.location.reload();
  };

  /*==================================
    FETCH THE FORM DATA
  ===================================*/
  const {
    data: formFieldsData,
    isLoading: formFieldsLoading,
    error,
  } = useOnlineApplicationGetSpecificForm(slug);
  const [redirectLoading, setRedirectLoading] = useState<boolean>(false);

  // If there's a 404 error with the form, redirect
  // the user to the 404 page
  useEffect(() => {
    if (!error) return;
    navigate("/404/");
  }, [error]);

  // Check if there is valid data returned from the API
  // before returning it to be used to populate the form
  const FORM_FIELDS_DATA = useMemo(() => {
    if (!formFieldsData || !Object.entries(formFieldsData) || formFieldsLoading) {
      return {
        name: "N/A",
        company_name: "N/A",
        components: [],
        status: null,
        position: null,
      };
    }

    // If a redirect URL is received, we instantly redirect the user,
    // without trying to generate the dynamic online application form at all
    if (formFieldsData.redirect_url) {
      setRedirectLoading(true);
      window.location.replace(formFieldsData.redirect_url);

      return {
        name: "N/A",
        company_name: "N/A",
        components: [],
        status: null,
        position: null,
      };
    }

    return formFieldsData;
  }, [formFieldsData]);

  // If we have received a 'tracker' field with an 'appcast' value from the API response,
  // append the "Job View Pixel" script for application tracking
  useEffect(() => {
    if (formFieldsData?.tracker === "appcast") {
      const jobViewPixelScript = document.createElement("script");
      jobViewPixelScript.src = "https://click.appcast.io/pixels/generic1-19004.js?ent=147";
      jobViewPixelScript.type = "text/javascript";
      document.body.appendChild(jobViewPixelScript);

      // Clean up - remove the script on unmount
      return () => {
        document.body.removeChild(jobViewPixelScript);
      };
    }
  }, [formFieldsData]);

  /*==================================
    GENERATE THE INITIAL FORM VALUES
  ===================================*/
  const [formInitialValues, setFormInitialValues] = useState<OnlineApplicationFormInitialValues>(
    {},
  );
  const [formValidationSchema, setFormValidationSchema] = useState<any>({});

  useEffect(() => {
    if (!FORM_FIELDS_DATA.components || !FORM_FIELDS_DATA.components.length) return;

    const initialFormFieldValues = handleFormFieldsInitialValues(FORM_FIELDS_DATA.components);
    const validationSchema = handleRegularFormFieldsValidation(FORM_FIELDS_DATA.components);
    const validationDynamicSectionsSchema = handleFormArraySectionsFieldValidationSchemas(
      FORM_FIELDS_DATA.components,
    );

    // Join the validation schemas into a single as Yup validation object
    const validationSchemaObject = Yup.object().shape({
      ...validationSchema,
      ...validationDynamicSectionsSchema,
    });

    // Pre-select the "position" field if related values are
    // received in the response from the API
    if (FORM_FIELDS_DATA.position && FORM_FIELDS_DATA.status.toLowerCase() === "active") {
      initialFormFieldValues["position"] = FORM_FIELDS_DATA.position;
    }
    setFormInitialValues(initialFormFieldValues);
    setFormValidationSchema(validationSchemaObject);
  }, [FORM_FIELDS_DATA]);

  /*==================================
    GOOGLE ReCAPTCHA TOKEN
  ===================================*/
  const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null);

  // Update the state for the recaptcha token,
  // so the users can proceed with submitting the form
  // NOTE: If the ReCAPTCHA has expired, this function will
  // be automatically called with and the token value will be set to `null`
  const handleGoogleRecaptchaToken = (token: string | null) => setRecaptchaToken(token);

  /*==================================
    SUBMIT THE FORM APPLICATION
  ===================================*/
  const [ongoingSubmission, setOnGoingSubmission] = useState<boolean>(false);

  const handleApplicationSubmission = async (values: FormikValues) => {
    // If there's no recaptcha value available, prevent form submission
    if (!recaptchaToken || !slug) {
      toast.error("ReCAPTCHA must be checked before submitting form.");
      return;
    }

    // Display loading indicator in the "Submit" button
    // while the API request for submitting the application is ongoing
    setOnGoingSubmission(true);

    // Remove the 'ToS' field from the final values object
    delete values.terms_and_conditions;

    try {
      const formData = new FormData();

      for (const [key, value] of Object.entries(values)) {
        // If the value is falsey (e.g. null, empty string, false, etc.),
        // do not include it in the constructed form data
        if (!value) continue;

        // If the form field is an array (meaning it represents the dynamic sections)
        // then we extract all fields from the objects belonging to this array, and
        // append them to the form data that will be sent over to the API.
        if (Array.isArray(value)) {
          // Filter out the dynamic section's array entries
          // that do not have valid values associated with them
          const filteredDynamicSection = value.filter(arrayEntry => {
            return Object.values(arrayEntry).some(fieldValue => fieldValue);
          });

          filteredDynamicSection.forEach((arrayField, index) => {
            Object.entries(arrayField).forEach(field => {
              const [fieldKey, fieldValue] = field;

              // Append all of the individual fields from the dynamic section to the form data
              formData.append(`${fieldKey}_${index + 1}`, fieldValue || ("" as any));
            });
          });
        } else {
          formData.append(key, value);
        }
      }

      // Read the URL search parameters from the visited page
      const { search } = window.location;

      const { assessment_identifier: identifier, sbca_disabled } = (await fetchHandlerUpload(
        "POST",
        `online-application/${slug}/submit${search}`,
        formData,
      )) as { assessment_identifier: string; sbca_disabled: boolean };

      // If SBCA is disabled on company level then redirect the user to the "Thank You" page directly
      // If SBCA is not disabled on company level then redirect the user to the page prompting the user
      // to fill out a SBCA questionnaire
      const tracker: string = formFieldsData?.tracker || "";

      if (sbca_disabled) {
        const strippedSearchParameters: string = search.slice(1);
        navigate(
          `/online-application/assessment/thank-you/?sbca_disabled=true${
            strippedSearchParameters ? `&${strippedSearchParameters}` : ""
          }${tracker ? `&tracker=${tracker}` : ""}`,
        );
      } else {
        const searchParams = search
          ? `${search}${tracker ? `&tracker=${tracker}` : ""}`
          : tracker
          ? `?tracker=${tracker}`
          : "";
        navigate(`/online-application/initialize-oa/thank-you/${identifier}/${searchParams}`);
      }
    } catch (error) {
      errorReporting(
        "Failed submitting online application form",
        error,
        {
          ...values,
          tracker: formFieldsData?.tracker || "",
        },
        "critical",
      );
    } finally {
      setOnGoingSubmission(false);
    }
  };

  /*=========================
    HANDLE FORM JOB POSITION

    Construct the title to be used for the form
    based on received values from the API, and if 
    the user reached the form trough a Job Ad URL
  ==========================*/
  const [formTitle, setFormTitle] = useState<string>("");
  const [formHidePositionDropdown, setFormHidePositionDropdown] = useState<boolean>(false);

  useEffect(() => {
    const { position, status, company_name } = FORM_FIELDS_DATA;

    // Exit function if no value was received
    if (!position || !status) {
      setFormTitle(`Applying for a position at ${company_name}`);
      return;
    }

    // Indicator for what we should dispaly in the title, and
    // if the dropdown menu for selecting the job position should be visible
    const isPositionPreselected: boolean = Boolean(position && status.toLowerCase() === "active");

    // Update the text to be displayed in the title
    if (isPositionPreselected) {
      setFormTitle(`Applying for ${position} at ${company_name}`);
    } else {
      setFormTitle(`Applying for a position at ${company_name}`);
    }

    setFormHidePositionDropdown(isPositionPreselected);
  }, [FORM_FIELDS_DATA]);

  /*==========================================
    IF CLIENT IS DISABLED 
    RENDER FALLBACK UI WITHOUT THE FORM
  ============================================*/
  if (formFieldsData && formFieldsData.is_company_disabled) {
    return <OnlineApplicationDisabledClient />;
  }

  return (
    <div className="online-application">
      {FORM_FIELDS_DATA.status?.toLowerCase() === "expired" ? (
        <Alert
          text={`Thank you for your interest in a career opportunity at ${FORM_FIELDS_DATA.company_name}. Although the help wanted ad you clicked on may have expired, we are always looking for talented individuals to join our winning team. Please select a position from the drop down menu below, then complete your application.`}
          type="info"
          modifierClass="alert--centered"
        />
      ) : null}

      <div
        className={`online-application__container ${
          formFieldsLoading || redirectLoading ? "online-application__container--loading" : ""
        }`}
      >
        {formFieldsLoading || redirectLoading ? (
          <Loader size="page" modifierWrapper="loader--page" />
        ) : (
          <>
            <Formik
              initialValues={formInitialValues}
              enableReinitialize
              validationSchema={formValidationSchema}
              onSubmit={handleApplicationSubmission}
            >
              {({ values, errors, touched, submitForm }) => (
                <Form
                  onSubmit={e => {
                    handleFormikScrollToFirstError(e, errors, ".input__error", submitForm);
                  }}
                >
                  <h1 className="online-application__title">{formTitle}</h1>

                  <p>
                    Internal use only. This information will not be shared with any third parties.
                  </p>

                  {FORM_FIELDS_DATA.components.map(formComponent => (
                    <>
                      <h5 className="online-application__section__title">
                        {formComponent.title}

                        {formComponent.description && (
                          <span className="online-application__section__description">
                            {formComponent.description}
                          </span>
                        )}
                      </h5>

                      <div className="row">
                        {formComponent.dynamic ? (
                          <OnlineApplicationArraySection
                            sectionComponents={formComponent}
                            values={values}
                          />
                        ) : (
                          formComponent.section_fields.map(formField => (
                            <div className={`col-12 ${handleFormFieldSizes(formField.width)}`}>
                              {formField.name === "position" && formHidePositionDropdown ? null : (
                                <Field
                                  id={formField.name}
                                  name={formField.name}
                                  component={handleFormFieldComponentMapping(
                                    formField.name === "phone" ? "phone" : formField.type,
                                  )}
                                  {...handleFormFieldComponentProps(formField, values)}
                                />
                              )}
                            </div>
                          ))
                        )}
                      </div>
                    </>
                  ))}

                  <TermsOfService />
                  {errors.terms_and_conditions && touched.terms_and_conditions ? (
                    <span className="d-block input__error">
                      <ErrorMessage name="terms_and_conditions" />
                    </span>
                  ) : null}

                  <ReCAPTCHA
                    sitekey={GOOGLE_RECAPTCHA_KEY}
                    className="online-application__recaptcha"
                    onChange={handleGoogleRecaptchaToken}
                    onErrored={() => setRecaptchaToken(null)}
                  />

                  <Button
                    modifierClass="online-application__btn__submit btn btn--fluid"
                    isDisabled={!recaptchaToken || ongoingSubmission}
                    isLoading={ongoingSubmission}
                  >
                    Submit
                  </Button>
                </Form>
              )}
            </Formik>

            <img src={ComodoSecureLogo} alt="Comodo Secure" />
          </>
        )}
      </div>

      <div className="container container--md">
        <OnlineApplicationFooter />
      </div>
    </div>
  );
};

export default OnlineApplication;
