// Hooks & Utilities
import { useGetAvailableTours } from "../../api/Tours/Tours";
import { useNavigate } from "react-router-dom";

// Components
import Skeleton from "react-loading-skeleton";
import ContentHeader from "../../components/Content/ContentHeader";

// Interfaces
import { ResourcesGroups, ResourcesItem } from "./interfaces";

// PDF assets
import { RESOURCES_GROUPS } from "./statics";

const Resources = () => {
  const { data: toursData, isLoading: toursLoading } = useGetAvailableTours();

  const navigate = useNavigate();

  return (
    <div className="container py--25">
      <ContentHeader title="Resources" modifierClass="mb--25" />
      <div key={`resource-group-tour`}>
        <h4 className="fw--medium mb--15">New Feature Guides</h4>
        <div className="mb--30 d-flex flex-column align-items-start">
          {toursLoading ? (
            <Skeleton width={170} />
          ) : toursData && toursData.length ? (
            toursData.map(tour => (
              <div
                key={`tour-${tour.id}`}
                onClick={() => navigate(`/applications/?activeTour=${tour.name}`)}
                className="btn btn--text--secondary txt--left btn--font-md mb--10"
              >
                {tour.label}
              </div>
            ))
          ) : null}
        </div>
      </div>

      {RESOURCES_GROUPS.map((resourceGroup: ResourcesGroups, groupIndex: number) => (
        <div key={`resource-group-${groupIndex}`}>
          <h4 className="fw--medium mb--15">{resourceGroup.title}</h4>

          <div className="mb--30 d-flex flex-column align-items-start">
            {resourceGroup.pdfs.map((resource: ResourcesItem, resourceIndex: number) => (
              <a
                key={`resource-${resourceIndex}`}
                href={`https://fch-assets.s3.amazonaws.com/${resource.link}`}
                className="btn btn--text--secondary txt--left btn--font-md mb--10"
                target="_blank"
                rel="noopener"
              >
                {resource.text}
              </a>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};
export default Resources;
