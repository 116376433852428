// Utilities & HOoks
import { AnimatePresence } from "framer-motion";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useVideoConferencingGetLink } from "../../api/VideoConferencing/VideoConferencing";

// Components
import Button from "../../components/Button/Button";
import Loader from "../../components/Loader/Loader";
import Modal from "../../components/Modal/Modal";

const VideoConferencing = () => {
  /*===========================
    HELP MODAL
  ============================*/
  const [showHelpModal, setShowHelpModal] = useState<boolean>(false);

  /*===========================
    MEETING LINK
  ============================*/
  const [meetingLink, setMeetingLink] = useState<string>("");
  const { data, isLoading, error } = useVideoConferencingGetLink();

  // If details are fetched from the API, construct the meeting link
  // that will be displayed in the UI
  useEffect(() => {
    if (!data || !Object.entries(data).length || isLoading) return;

    const { host, protocol } = window.location;
    const constructedMeetingLink: string = `${protocol}//${host}/vidconf/join/${data.video_link}`;
    setMeetingLink(constructedMeetingLink);
  }, [data, isLoading]);

  const handleCopyMeetingLink = async () => {
    try {
      await navigator.clipboard.writeText(meetingLink);
      toast.info("Meeting link copied!");
    } catch (error) {
      toast.error("Failed copying meeting link!");
    }
  };

  return (
    <div className="video-conferencing video-conferencing--app">
      {isLoading ? (
        <Loader size="page" modifierWrapper="loader--page" />
      ) : (
        <>
          {!data || !Object.entries(data).length || error ? (
            <div className="d-flex flex-column justify-content-center align-items-center py--30">
              <h2 className="mb--20">
                There was an issue obtaining the video conferencing details. Please reload the page
                and try again.
              </h2>
              <Button
                modifierClass="btn--fluid btn--primary"
                onClick={() => window.location.reload()}
              >
                Reload Page
              </Button>
            </div>
          ) : (
            <>
              <div className="video-conferencing__details">
                <div
                  className="video-conferencing__details__box"
                  onClick={() => setShowHelpModal(true)}
                >
                  ?
                </div>

                <p onClick={handleCopyMeetingLink} className="d-flex flex-column flex-md-row">
                  <strong>Meeting link:</strong>
                  <p> {meetingLink}</p>
                </p>
              </div>

              <div className="video-conferencing__container">
                <div className="video">
                  <iframe
                    title="Video Conference"
                    src={`https://tokbox.com/embed/embed/ot-embed.js?embedId=${data.embed_id}&iframe=true&room=${data.video_link}`}
                    width="1024px"
                    height="576px"
                    scrolling="no"
                    allow="microphone; camera"
                  ></iframe>
                </div>
              </div>

              <AnimatePresence>
                {showHelpModal ? (
                  <Modal
                    title="Video Conferencing Help"
                    text=""
                    modifierClass="modal--fixated video-conferencing__help-modal"
                    handleCloseModal={() => setShowHelpModal(false)}
                  >
                    <div className="video-conferencing__help-modal__body">
                      <p>
                        Getting started with FirstChoice Hiring Video Conferencing is easy and just
                        involves a few steps.
                      </p>

                      <ul>
                        <li>
                          Copy the meeting link (Control + C) next to the help icon and email it to
                          your applicant and any other attendees.
                        </li>
                        <li>
                          Don't forget to include a meeting time as your conference room is always
                          available.
                        </li>
                        <li>
                          Be sure to allow for enough time between meetings for people leaving the
                          previous meeting.
                        </li>
                        <li>Click the button labeled "Click Here to Start Video Chat".</li>
                        <li>Limit participants to 3-4 maximum for optimal performance.</li>
                        <li>
                          Mute microphone after speaking to avoid feedback and background noise
                          using the "Toggle Microphone" button at the bottom of the conference
                          window.
                        </li>
                      </ul>

                      <p>
                        If you encounter any issues, don't hesistate to reach out to our support
                        team at 1-877-449-7595.
                      </p>
                    </div>

                    <div className="video-conferencing__help-modal__footer">
                      <Button
                        modifierClass="btn--fluid btn--danger"
                        onClick={() => setShowHelpModal(false)}
                      >
                        Close
                      </Button>
                    </div>
                  </Modal>
                ) : null}
              </AnimatePresence>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default VideoConferencing;
