type LocalStorageKeys =
  | "accessToken"
  | "refreshToken"
  | "expiresIn"
  | "autologin"
  | "fch-chats"
  | "fch-email"
  | "dashboard_view_mode";

/**
 *
 * This object represents a mapping between the specific local storage keys that are being
 * used in the application and the type of action that will be taken
 * when obtaining or storing the value in local storage.
 *
 * A "parse" action (e.g. for `expiresIn`) represents the following:
 *
 * - before obtaining an item from local storage, that is associated with the "parse" action,
 * the "JSON.parse" method will be called before returning the value
 *
 * - before storing an item into local storage, that is associated with the "parse" action,
 * the "JSON.stringify" method will be called before the value is stored
 *
 * A "non-parse" action represents that the item that was obtained, or the item that is to be stored, will be
 * in its original form without any additional steps.
 *
 */
const LocalStorageActionMappings: Record<LocalStorageKeys, "parse" | "no-parse"> = {
  accessToken: "no-parse",
  refreshToken: "no-parse",
  expiresIn: "parse",
  autologin: "parse",
  "fch-chats": "parse",
  "fch-email": "no-parse",
  dashboard_view_mode: "no-parse",
};

class LocalStorageUtilities {
  /**
   *
   * Utility function that checks if Local Storage API is available at all in the browser,
   * in order to prevent "Insecure Operations" errors when this API is not available.
   *
   * Code taken from MDN: https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API#feature-detecting_localstorage
   *
   */
  private checkExistanceOfLocalStorage() {
    let storage;
    try {
      storage = window["localStorage"];
      const storageTest = "__storage_test__";
      storage.setItem(storageTest, storageTest);
      storage.removeItem(storageTest);
      return true;
    } catch (e) {
      return (
        e instanceof DOMException &&
        // everything except Firefox
        (e.code === 22 ||
          // Firefox
          e.code === 1014 ||
          // test name field too, because code might not be present
          // everything except Firefox
          e.name === "QuotaExceededError" ||
          // Firefox
          e.name === "NS_ERROR_DOM_QUOTA_REACHED") &&
        // acknowledge QuotaExceededError only if there's something already stored
        storage &&
        storage.length !== 0
      );
    }
  }

  /**
   * Checks if "Local Storage" API is available to work with and returns a `boolean`
   * based on its availability that is then used as a safe-guard within the other methods.
   *
   * @returns Boolean indicating if the API is available or not.
   *
   **/
  private checkLocalStorageAvailability(): boolean {
    if (!this.checkExistanceOfLocalStorage() || !navigator.cookieEnabled || !window.localStorage) {
      return false;
    }

    return true;
  }

  /**
   *
   * Obtain the targeted key from local storage, including additional functionality:
   *
   * - check for availability of local storage API
   * - check for availabilty of stored items to work with
   * - parsing of stored value that will be obtained from local storage (if needed)
   *
   * @param key Specific key used in the application that we want to obtain from local storage
   *
   */
  public getItem(key: LocalStorageKeys) {
    // Exit function if local storage is not available
    if (!this.checkLocalStorageAvailability()) return;

    // Exit function if there are no items to work with in local storage
    if (!window.localStorage.length) return;

    // Obtain the value of the specific key that was saved in local storage,
    // and if it cannot be found exit function returning default value
    const storedValue = window.localStorage.getItem(key);
    if (!storedValue) return "";

    try {
      if (LocalStorageActionMappings[key] === "parse") {
        return JSON.parse(storedValue);
      } else {
        return storedValue;
      }
    } catch (error) {
      throw new Error("Local storage utility function - JSON.parse action - failed");
    }
  }

  /**
   *
   * Save a specific key/value pair in local storage, with additional functionality:
   *
   * - check for availability of local storage API
   * - stringifies the value before storing it in local storage (if needed)
   *
   * @param key Specific key to be used in the application that we want to save in local storage
   * @param value Specific value that will be used in the application (e.g. tokens)
   *
   */
  public saveItem(key: LocalStorageKeys, value: unknown) {
    // Exit function if local storage is not available
    if (!this.checkLocalStorageAvailability()) return;

    // Exit function if there's no value to work with
    if (!value) return;

    try {
      if (LocalStorageActionMappings[key] === "parse") {
        window.localStorage.setItem(key, JSON.stringify(value));
      } else {
        window.localStorage.setItem(key, value as string);
      }
    } catch (error: any) {
      throw new Error(error);
    }
  }

  /**
   *
   * Remove a specific key from local storage.
   *
   * Includes check for availability of local storage API.
   *
   * @param key Specific key that was being used in the application prior its deletion
   *
   */
  public removeItem(key: LocalStorageKeys) {
    // Exit function if local storage is not available
    if (!this.checkLocalStorageAvailability()) return;

    window.localStorage.removeItem(key);
  }

  /**
   *
   * Clears out the local storage entirely.
   *
   */
  public clear() {
    window.localStorage.clear();
  }
}

export const LocalStorageActions = new LocalStorageUtilities();
