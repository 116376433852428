import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { useAuth } from "../../providers/auth-context";
import fetchHandler from "../fetchHandler";
import {
  AppointmentsListResponseFields,
  AppointmentsDeleteAppointmentDetails,
  AppointmentsEditInPersonAppointmentDetails,
  AppointmentsEditVideoMeetingAppointmentDetails,
  AppointmentsInPersonRequestDetails,
  AppointmentsVideoMeetingRequestDetails,
} from "./interfaces";

/**
 *
 * Get the list of all existing Appointments for the
 * selected month & year combination.
 *
 * @param month A capitalized string representing the selected `month` value.
 * @param year A string representing the selected `year` value
 *
 */
export function useAppointmentsGet(month: string, year: string) {
  // Read the currently active company's slug
  const { user } = useAuth();
  const companySlug: string = user.active_company.slug;

  return useQuery(
    ["appointments", companySlug, month, year],
    async () => {
      return (await fetchHandler(
        "GET",
        `company/${companySlug}/appointments?month=${month}&year=${year}`,
      )) as AppointmentsListResponseFields[];
    },
    { enabled: !!companySlug && (!!month || !!year) },
  );
}

/**
 *
 * Get the list of available month/year combinations
 * for listing the appointments.
 *
 */
export function useAppointmentsGetAvailableMonths() {
  // Read the currently active company's slug
  const { user } = useAuth();
  const companySlug: string = user.active_company.slug;

  return useQuery(
    ["appointments-months", companySlug],
    async () => {
      return (await fetchHandler("GET", `company/${companySlug}/appointments/months`)) as string[];
    },
    { enabled: !!companySlug },
  );
}

/**
 *
 * Schedule a new "In-Person" appointment for the targeted application
 *
 */
export function useAppointmentsScheduleInPerson() {
  const queryClient = useQueryClient();

  // Read the currently active company's slug
  const { user } = useAuth();
  const companySlug: string = user.active_company.slug;

  return useMutation(
    async (appointmentDetails: AppointmentsInPersonRequestDetails) => {
      return await fetchHandler<AppointmentsInPersonRequestDetails>(
        "POST",
        `company/${companySlug}/applications/${appointmentDetails.application_id}/appointments`,
        appointmentDetails,
      );
    },
    {
      onSuccess: () => toast.success("In-Person appointment scheduled successfully!"),
      onError: error => error,
      onSettled: (_data, _error, details) => {
        queryClient.invalidateQueries(["appointments", companySlug]);
        queryClient.invalidateQueries(["applications", companySlug]);
        queryClient.invalidateQueries([
          "applications-details",
          details.application_id,
          companySlug,
        ]);
      },
    },
  );
}

/**
 *
 * Edit the targeted "In-Person" appointment details
 *
 */
export function useAppointmentsEditInPersonAppointment() {
  const queryClient = useQueryClient();

  // Read the currently active company's slug
  const { user } = useAuth();
  const companySlug: string = user.active_company.slug;

  return useMutation(
    async (editDetails: AppointmentsEditInPersonAppointmentDetails) => {
      return await fetchHandler(
        "PUT",
        `company/${companySlug}/applications/${editDetails.application_id}/appointments/${editDetails.appointment_id}`,
        editDetails,
      );
    },
    {
      onSuccess: () => toast.success("Appointment details edited successfully!"),
      onError: error => error,
      onSettled: (_data, _error, editDetails) => {
        queryClient.invalidateQueries(["appointments", companySlug]);
        queryClient.invalidateQueries([
          "applications-details",
          editDetails.application_id,
          companySlug,
        ]);
      },
    },
  );
}

/**
 *
 * Schedule a new "Video Meeting" appointment for the targeted application
 *
 */
export function useAppointmentsScheduleVideoMeeting() {
  const queryClient = useQueryClient();

  // Read the currently active company's slug
  const { user } = useAuth();
  const companySlug: string = user.active_company.slug;

  return useMutation(
    async (appointmentDetails: AppointmentsVideoMeetingRequestDetails) => {
      return await fetchHandler<AppointmentsVideoMeetingRequestDetails>(
        "POST",
        `company/${companySlug}/applications/${appointmentDetails.application_id}/appointments`,
        appointmentDetails,
      );
    },
    {
      onSuccess: () => toast.success("Video meeting appointment scheduled successfully!"),
      onError: error => error,
      onSettled: (_data, _error, details) => {
        queryClient.invalidateQueries(["appointments", companySlug]);
        queryClient.invalidateQueries(["applications", companySlug]);
        queryClient.invalidateQueries([
          "applications-details",
          details.application_id,
          companySlug,
        ]);
      },
    },
  );
}

/**
 *
 * Edit the targeted "Video Meeting" appointment details
 *
 */
export function useAppointmentsEditVideoMeetingAppointment() {
  const queryClient = useQueryClient();

  // Read the currently active company's slug
  const { user } = useAuth();
  const companySlug: string = user.active_company.slug;

  return useMutation(
    async (editDetails: AppointmentsEditVideoMeetingAppointmentDetails) => {
      return await fetchHandler(
        "PUT",
        `company/${companySlug}/applications/${editDetails.application_id}/appointments/${editDetails.appointment_id}`,
        editDetails,
      );
    },
    {
      onSuccess: () => toast.success("Appointment details edited successfully!"),
      onError: error => error,
      onSettled: (_data, _error, editDetails) => {
        queryClient.invalidateQueries(["appointments", companySlug]);
        queryClient.invalidateQueries([
          "applications-details",
          editDetails.application_id,
          companySlug,
        ]);
      },
    },
  );
}

/**
 *
 * Delete the targeted appointment
 *
 * The mutation hook takes in the `appointmentID` argument.
 *
 */
export function useAppointmentsDelete() {
  const queryClient = useQueryClient();

  // Read the currently active company's slug
  const { user } = useAuth();
  const companySlug: string = user.active_company.slug;

  return useMutation(
    async (deleteDetails: AppointmentsDeleteAppointmentDetails) => {
      return await fetchHandler(
        "DELETE",
        `company/${companySlug}/applications/${deleteDetails.application_id}/appointments/${deleteDetails.appointment_id}`,
      );
    },
    {
      onSuccess: () => toast.success("Appointment deleted successfully!"),
      onError: error => error,
      onSettled: (_data, _error, deleteDetails) => {
        queryClient.invalidateQueries(["appointments", companySlug]);
        queryClient.invalidateQueries([
          "applications-details",
          deleteDetails.application_id,
          companySlug,
        ]);
      },
    },
  );
}
