// Hooks & Utilities
import { format } from "date-fns";
import { useApplicationResumeModal } from "./hooks/useApplicationResumeModal";
import { useEffect, useRef, useState } from "react";
import { AnimatePresence } from "framer-motion";

// Components
import { Link } from "react-router-dom";
import ApplicationAppointmentsAndComments from "./ApplicationAppointmentsAndComments";
import ApplicationCardHeader from "./ApplicationCardHeader";
import ApplicationContact from "./ApplicationContact";
import ApplicationDetails from "./ApplicationDetails";
import ApplicationPhoto from "./ApplicationPhoto";
import ApplicationRating from "./ApplicationRating";
import ApplicationSocials from "./Socials/ApplicationSocials";
import ApplicationSourceAdmin from "./ApplicationSourceAdmin";
import ApplicationSourceUser from "./ApplicationSourceUser";
import PermissionCheckComponentWrapper from "../Wrappers/PermissionCheckComponentWrapper";
import ApplicationDateSection from "./ApplicationDateSection";

// Components - Buckets
import ApplicationArchive from "./Buckets/ApplicationArchive";
import ApplicationFavorite from "./Buckets/ApplicationFavorite";

// Components - Socials
import ApplicationSendText from "./Socials/ApplicationSendText";
import ApplicationResume from "./Socials/ApplicationResume";
import ApplicationModalResumeRequest from "./modals/ApplicationModalResumeRequest";
import ApplicationLinkedIn from "./Socials/ApplicationLinkedIn";
import ApplicationScheduleMeeting from "./Socials/ApplicationScheduleMeeting";
import Modal from "../Modal/Modal";
import ScheduleMeetingMenu from "./ScheduleMeeting/ScheduleMeetingMenu";
import ApplicationSendEmail from "./Socials/ApplicationSendEmail";
// Interfaces
import { ApplicationCardProps } from "./interfaces";

const ApplicationCardList = ({
  details,
  selectedApplications,
  isFocused = false,
  activeBucket,
  handleApplicationsSelection,
  handleApplicationResumePreview,
}: ApplicationCardProps) => {
  /*===============================
    RESUME MODALS
  ================================*/
  const [resumeModalType, handleResumeModal] = useApplicationResumeModal();

  useEffect(() => {
    if (resumeModalType !== "preview") return;
    handleApplicationResumePreview(details);
  }, [resumeModalType]);

  /*===============================
    SCHEDULE MEETING
  ================================*/
  const [isScheduleMeetingModalOpen, setIsScheduleMeetingModalOpen] = useState<boolean>(false);

  // If there's a "Video Meeting" scheduled for a future date,
  // redirect the user to the video conference page. Otherwise open the modal.
  const handleScheduleMeeting = () => {
    if (details.schedule_meeting) {
      window.open(`/vidconf/join/${details.schedule_meeting}`);
    } else {
      setIsScheduleMeetingModalOpen(true);
    }
  };

  /*===============================
    FOCUS ON THE APPLICATION
  ================================*/
  const applicationCardRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (!isFocused) return;

    if (applicationCardRef.current) {
      applicationCardRef.current.scrollIntoView({
        block: "center",
        inline: "center",
        behavior: "auto",
      });
    }
  }, [isFocused]);

  return (
    <div
      ref={applicationCardRef}
      className={`applications applications--list ${
        selectedApplications.includes(details.id) ? "applications--selected" : ""
      } ${details.is_hidden ? "applications--hidden" : ""} ${
        isFocused ? "applications--is-focused" : ""
      }`}
      key={details.id}
    >
      {/* APPLICATION SBCA */}
      <ApplicationCardHeader
        id={details.id}
        name={details.name}
        email={details.email}
        sbca={details.sbca}
        hired_date={null}
        activeBucket={null}
        applicantBucket={null}
        sbca_report_identifier={details.sbca_report_identifier}
        sbca_request_date={details.sbca_request_date}
        isSelected={selectedApplications.includes(details.id)}
        handleApplicationSelection={() => handleApplicationsSelection(details.id)}
      />

      {/* APPLICATION DETAILS:
        - PHOTO
        - NAME 
        - ROLE 
        - CONTACT 
        - RATING 
        - REGISTRATION DATE 
      */}
      <div className="applications__list-details-wrapper">
        <div className="d-flex flex-column flex-sm-row justify-content-center align-items-center">
          <Link to={`/applications/${details.id}/`}>
            <ApplicationPhoto name={details.name} photo={details.photo} sbca={details.sbca} />
          </Link>

          <div className="ml--sm--0 ml--20">
            <ApplicationDetails id={details.id} name={details.name} position={details.position} />
            <ApplicationContact location={details.location} phone={details.phone} />
            {/* APPLICATION SOURCE */}
            <ApplicationSourceAdmin
              source={details.source}
              modifierClass="applications__source--list"
            />
          </div>
        </div>

        <div>
          {/* APPLICATION HIRED DATE/APPLICATION DATE */}
          <div className="applications__list-details-rating">
            <ApplicationDateSection
              active_bucket={activeBucket}
              applicant_hired_date={details.hired_date}
              applicant_name={details.name}
              application_id={details.id}
              registration_date={details.registration_date}
              applicant_bucket={details.buckets[0]}
            />

            <ApplicationRating
              comments_avg_rating={details.comments_avg_rating}
              modifierClass="fw--bold"
            />
          </div>
          <ApplicationSourceUser
            source={details.source}
            modifierClass="applications__source__user--list"
          />
        </div>
      </div>

      {/* APPLICATION ACTIONS:
        - ACTION BUTTONS (Archive & Favorite)
        - SOCIALS LINKS
        - APPOINTMENTS AND COMMENTS
        - APPLICATION DETAILS LINK
      */}
      <div className="applications__list-actions-wrapper">
        <PermissionCheckComponentWrapper permissions={["buckets_view"]}>
          <div className="applications__list-actions-ordering--buttons d-flex justify-content-lg-center align-items-center mr--30 mr--md--0">
            <ApplicationArchive
              id={details.id}
              currentBuckets={details.buckets}
              name={details.name}
            />
            <ApplicationFavorite
              id={details.id}
              currentBuckets={details.buckets}
              name={details.name}
            />
          </div>
        </PermissionCheckComponentWrapper>

        <div className="applications__list-actions-ordering--socials d-flex justify-content-between align-items-center mr--30 mr--md--0">
          <ApplicationSocials>
            <ApplicationSendText
              application_id={details.id}
              applicant_details={{
                id: details.applicant_id,
                text_message_consent: details.text_message_consent,
                name: details.name,
                photo: details.photo,
              }}
            />

            <ApplicationResume
              resume={details.resume}
              tooltipText={
                details.resume
                  ? "Preview Resume"
                  : details.resume_request_date
                  ? `Resume Requested On ${format(
                      new Date(details.resume_request_date),
                      "MM/dd/yyyy",
                    )}. Click to Request Again`
                  : "Request Resume"
              }
              handleResume={resume => {
                handleResumeModal(resume ? "preview" : "request");
                if (resumeModalType === "preview") handleApplicationResumePreview(details);
              }}
            >
              <AnimatePresence>
                {resumeModalType === "request" && (
                  <ApplicationModalResumeRequest
                    id={details.id}
                    name={details.name}
                    email={details.email}
                    handleCloseModal={() => handleResumeModal(null)}
                  />
                )}
              </AnimatePresence>
            </ApplicationResume>

            <ApplicationLinkedIn linkedin={details.linkedin} />

            <ApplicationSendEmail
              details={{
                applicantName: details.name,
                applicantEmail: details.email,
                applicationDate: details.registration_date,
                applicationPosition: details.position,
                applicantLocation: details.location,
              }}
            />

            <ApplicationScheduleMeeting
              meeting_type="video"
              tooltipText={
                details.schedule_meeting ? "Start Video Interview" : "Video Interview"
              }
              handleScheduleMeeting={handleScheduleMeeting}
            />
          </ApplicationSocials>
        </div>

        <div className="d-flex flex-column align-items-center order-last">
          <ApplicationAppointmentsAndComments
            applicationID={details.id}
            appointments_count={details.appointments_count}
            comments_count={details.comments_count}
          />

          <Link to={`/applications/${details.id}/`} className="applications__details-link">
            View Details
          </Link>

          {details.is_hidden ? (
            <h6 className="btn btn--danger btn--pill btn--sm mx--auto mt--10 pointer-events-none">
              Hidden Application
            </h6>
          ) : null}
        </div>
      </div>

      <AnimatePresence>
        {isScheduleMeetingModalOpen && (
          <Modal
            title=""
            text=""
            handleCloseModal={() => setIsScheduleMeetingModalOpen(false)}
            modifierClass="modal--fixated applications-schedule-meeting-modal"
          >
            <ScheduleMeetingMenu
              applicationID={details.id}
              meeting_link={details.meeting_link ?? details.schedule_meeting ?? "N/A"}
              handleCloseMenu={() => setIsScheduleMeetingModalOpen(false)}
            />
          </Modal>
        )}
      </AnimatePresence>
    </div>
  );
};

export default ApplicationCardList;
