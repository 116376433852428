// Utilities & Hooks
import { useEffect, useState } from "react";
import { handleStringCapitalization } from "../../utilities/strings/handleStringCapitalization";
import { useExtractSearchParameters } from "../../hooks/useExtractSearchParameters";

// Components
import Card from "../../components/Card/Card";
import ContentHeader from "../../components/Content/ContentHeader";
import InputFieldSearch from "../../components/Inputs/InputFieldSearch";
import Tabs from "../../components/Tabs/Tabs";
import EmailLogs from "./EmailLogs";
import SMSLogs from "./SMSLogs";

// Interfaces
import { TabItemProps } from "../../components/Tabs/interfaces";
import { LogsCount } from "./interfaces";

const Logs = () => {
  const [searchParametersObject, setSearchParametersObject] = useExtractSearchParameters();
  const [searchedValue, setSearchedValue] = useState<string>("");
  const handleSearch = (searched: string) => setSearchedValue(searched);

  // Handling of displayed logs count based on selected tab
  const [logsCount, setLogsCount] = useState<LogsCount | null>(null);
  const handleLogsCount = (logsCount: LogsCount | null) => {
    setLogsCount(logsCount);
  };

  // Handling of currently active tab
  const [activeTab, setActiveTab] = useState<string>(() => searchParametersObject.tab || "emails");
  const handleTabChange = (tab: TabItemProps) => {
    setLogsCount(null);
    setSearchedValue("");
    setActiveTab(tab.id);
  };

  useEffect(() => {
    const { page } = searchParametersObject;

    // Remove the "page" search parameter on tab change (if present)
    if (page) delete searchParametersObject.page;

    setSearchParametersObject(searchParametersObject);
  }, [activeTab]);

  return (
    <div className="container py--25">
      <ContentHeader modifierClass="mb--20" title="Notification Logs">
        <InputFieldSearch
          handleOnSearch={handleSearch}
          placeholder={`Search ${handleStringCapitalization(activeTab, [" "])}`}
          modifierClass="mr--10"
          size="md"
        />
      </ContentHeader>

      <div className="d-flex flex-column-reverse flex-md-row align-items-start justify-content-between">
        <Tabs
          tabs={[
            { id: "emails", text: "Emails" },
            { id: "sms", text: "SMS" },
          ]}
          activeTabId={activeTab}
          handleTabSelection={tab => handleTabChange(tab)}
        />

        {logsCount ? (
          <h5
            className="px--20 pt--10 pb--0 pb--md--20 px--md--0"
            data-testid="components:logs-show-count"
          >
            Showing <strong>{logsCount.shownCount}</strong> out of{" "}
            <strong>{logsCount.totalCount}</strong> results
          </h5>
        ) : null}
      </div>

      <Card>
        {activeTab === "emails" ? (
          <EmailLogs searchedValue={searchedValue} handleLogsCount={handleLogsCount} />
        ) : (
          <SMSLogs searchedValue={searchedValue} handleLogsCount={handleLogsCount} />
        )}
      </Card>
    </div>
  );
};

export default Logs;
