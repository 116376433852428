import { useEffect } from "react";
import { useExtractSearchParameters } from "../../hooks/useExtractSearchParameters";
import { useAuth } from "../../providers/auth-context";
import { useTour } from "../../providers/tour-context";
import { ProductTourWrapperProps, ProductTourIds } from "./interfaces";
import ReactJoyride, { ACTIONS, CallBackProps, STATUS } from "react-joyride";
import ProductTourTooltip from "./ProductTourTooltip";

const ProductTourWrapper = ({
  pageTours,
  isLoading = false,
  isDisabled = false,
}: ProductTourWrapperProps) => {
  const [searchParametersObject] = useExtractSearchParameters();
  const { isTourRunning, activeTour, tourCurrentStep, handleTourStart, handleTourEnd } = useTour();
  const { user } = useAuth();

  // Automatically start the product tour if its available to the user
  useEffect(() => {
    if (!pageTours.length) {
      throw new Error("Please provide tours that exist for the page where this component is used.");
    }

    // Exit if there is a search parameter for a tour start or the user object contains no unwatched tours
    if (searchParametersObject.activeTour || !user || !user.unwatched_product_tours.length) return;

    // Use the intersection of the current page tours & the user object unwatched tours to find the startup tour id
    const found: ProductTourIds | undefined = pageTours.filter(tour =>
      user.unwatched_product_tours.map(unwatchedTour => unwatchedTour.name).includes(tour),
    )[0];

    if (found) handleTourStart(found);
  }, [user]);

  // Manually control handling the ongoing product tour ending
  const handleTourCallback = (data: CallBackProps) => {
    if (
      ([STATUS.ERROR, STATUS.SKIPPED, ACTIONS.CLOSE, STATUS.FINISHED] as string[]).includes(
        data.status,
      ) ||
      data.action === ACTIONS.CLOSE
    ) {
      handleTourEnd();
    }
  };

  // If there's no active tour at the moment, do not render anything
  if (!activeTour) return null;

  return !isLoading || !isDisabled ? (
    <div>
      <div className="joyride-tooltip__custom-overlay"></div>
      <ReactJoyride
        tooltipComponent={ProductTourTooltip}
        callback={handleTourCallback}
        run={isTourRunning}
        steps={activeTour ? activeTour.steps : []}
        continuous
        showProgress={true}
        key={activeTour?.tourId}
        stepIndex={activeTour?.type == "manual" ? tourCurrentStep : undefined}
        disableOverlayClose
        disableCloseOnEsc
      />
    </div>
  ) : null;
};

export default ProductTourWrapper;
