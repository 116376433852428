// Hooks
import { useState } from "react";

// Formik
import { Field, FieldArray } from "formik";

// Components
import FormInput from "../../Form/FormInput";
import FormTextarea from "../../Form/FormTextarea";
import Tooltip from "../../Tooltip/Tooltip";
import Banner from "../../Banner/Banner";
import Modal from "../../Modal/Modal";
import Button from "../../Button/Button";

// Assets
import { MdDeleteForever as DeleteHighlightIcon } from "react-icons/md";
import { BsInfoCircle as InfoIcon } from "react-icons/bs";

// Statics
import { CAREER_PAGES_FORM_HIGHLIGHTS } from "../../../pages/Career/statics";

// Interfaces
import { HighlightsSectionProps } from "./interfaces";
import { AnimatePresence } from "framer-motion";

const HighlightsSection: React.FC<HighlightsSectionProps> = ({ highlights }) => {
  /*===========================
    DOUBLE CONFIRMATION MODAL

    Display a double confirmation modal if
    the user wants to remove an existing highlight
  ============================*/
  const [showConfirmDeletionModal, setShowConfirmDeletionModal] = useState<boolean>(false);
  const [targetedHighlightIndex, setTargetedHighlightIndex] = useState<number | null>(null);

  // Reset to default values when the modal is closed
  const handleCloseDeleteModal = () => {
    setShowConfirmDeletionModal(false);
    setTargetedHighlightIndex(null);
  };

  return (
    <FieldArray
      name="highlights"
      render={arrayHelpers => (
        <>
          <h5 className="d-flex flex-column flex-md-row justify-content-between align-items-center fw--semibold my--20">
            A Day In the Life Highlights
            {highlights.length < 6 && (
              <span
                className="txt--link fw--semibold cursor--clickable my--20"
                onClick={() => arrayHelpers.push({ title: null, data: null })}
              >
                + Add Highlight <em>(6 max)</em>
              </span>
            )}
          </h5>

          <Banner
            text="Hint: Keep the Titles down to short phrases or 5 words max. Use the Explanation area
              to further explain each Highlight Title in more detail."
            icon={<InfoIcon />}
          />

          {highlights.length > 0 &&
            highlights.map((_, highlightIndex: number) => (
              <div className="row" key={`highlights-section-${highlightIndex}`}>
                <div className="col-12 col-lg-4">
                  <h6 className="career-pages__field__title mb--0i">
                    Highlight {highlightIndex + 1} Title
                  </h6>
                  <em className="career-pages__field__label d-block txt--sm fw--medium txt--light-gray">
                    {CAREER_PAGES_FORM_HIGHLIGHTS[highlightIndex].title_placeholder}
                  </em>

                  <Field
                    component={FormInput}
                    id={`highlights.${highlightIndex}.title`}
                    name={`highlights.${highlightIndex}.title`}
                    modifierClass="mb--15"
                  />
                </div>

                <div className="col-12 col-lg-8">
                  <h6 className="career-pages__field__title mb--0i">
                    Highlight {highlightIndex + 1} Short Explanation
                    <Tooltip text="Remove Highlight" size="md">
                      <DeleteHighlightIcon
                        className="career-pages__delete"
                        data-testid="component:career-pages-highlights-section-delete-btn"
                        onClick={() => {
                          setShowConfirmDeletionModal(true);
                          setTargetedHighlightIndex(highlightIndex);
                        }}
                      />
                    </Tooltip>
                  </h6>
                  <em className="career-pages__field__label d-block txt--sm fw--medium txt--light-gray">
                    {CAREER_PAGES_FORM_HIGHLIGHTS[highlightIndex].explanation_placeholder}
                  </em>

                  <Field
                    component={FormTextarea}
                    id={`highlights.${highlightIndex}.data`}
                    name={`highlights.${highlightIndex}.data`}
                    modifierClass="mb--15"
                    rows="6"
                  />
                </div>
              </div>
            ))}

          <AnimatePresence>
            {showConfirmDeletionModal && (
              <Modal
                title="Remove Highlight?"
                text="This action will remove the targeted Highlight from the Career Page. Are you sure you want to remove it?"
                modifierClass="modal--md modal--fixated"
                handleCloseModal={handleCloseDeleteModal}
              >
                <div className="modal__actions">
                  <Button
                    modifierClass="btn--fluid btn--primary"
                    onClick={handleCloseDeleteModal}
                    type="button"
                  >
                    Cancel
                  </Button>

                  <Button
                    modifierClass="txt--capitalize btn--fluid btn--secondary"
                    type="button"
                    onClick={() => {
                      // If there's no valid highlight index value, prevent action from executing
                      if (targetedHighlightIndex == null) return;

                      // Remove the targeted highlight from the array and close the modal
                      arrayHelpers.remove(targetedHighlightIndex);
                      handleCloseDeleteModal();
                    }}
                  >
                    Yes, Remove Highlight
                  </Button>
                </div>
              </Modal>
            )}
          </AnimatePresence>
        </>
      )}
    />
  );
};

export default HighlightsSection;
