// Components
import TableSkeletonPlaceholder from "../../components/SkeletonPlaceholders/TableSkeletonPlaceholder";
import Table from "../../components/Table/Table";

// Utilities & Hooks
import { createColumnHelper } from "@tanstack/react-table";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import { handleDateAsTimestamp } from "../../utilities/dates/handleDateAsTimestamp";

// Interfaces & API Hooks
import { EmailLogFields, useLogsEmailsGetAll } from "../../api/Logs/Logs";
import { EmailLLogsProps } from "./interfaces";

const EmailLogs = ({ searchedValue, handleLogsCount }: EmailLLogsProps) => {
  /*=========================
    GENERATE TABLE COLUMNS
  ==========================*/
  const COLUMN_HELPER = createColumnHelper<EmailLogFields>();

  const EMAILS_TABLE_COLUMNS = [
    COLUMN_HELPER.accessor("id", {
      header: () => <span>ID</span>,
      cell: data => <span>{data.getValue() || "N/A"}</span>,
      size: 30,
    }),
    COLUMN_HELPER.accessor("sent_timestamp", {
      header: () => <span>Sent</span>,
      cell: data => (
        <span>
          {data.getValue() != null
            ? format(handleDateAsTimestamp(data.getValue() as number), "MM/dd/yyyy HH:mm:ss")
            : "N/A"}
        </span>
      ),
      size: 110,
    }),
    COLUMN_HELPER.accessor("status", {
      header: () => <span>Status</span>,
      cell: data => (
        <span>{data.row.original.sent_timestamp !== null ? "Successful" : "Failed"}</span>
      ),
      size: 50,
    }),
    COLUMN_HELPER.accessor("from", {
      header: () => <span>From</span>,
      cell: data => (
        <Link
          to={`/notification-logs/emails/details/${data.row.original.id}/`}
          className="btn--text--secondary"
        >
          <span className="fw--medium">{data.getValue()}</span>
        </Link>
      ),
      size: 200,
    }),
    COLUMN_HELPER.accessor("to", {
      header: () => <span>To</span>,
      cell: data => (
        <Link
          to={`/notification-logs/emails/details/${data.row.original.id}/`}
          className="btn--text--secondary"
        >
          <span className="fw--medium">{data.getValue()}</span>
        </Link>
      ),
      size: 200,
    }),
    COLUMN_HELPER.accessor("subject", {
      header: () => <span>Subject</span>,
      cell: data => (
        <Link
          to={`/notification-logs/emails/details/${data.row.original.id}/`}
          className="btn--text--secondary"
        >
          <span className="fw--medium">{data.getValue()}</span>
        </Link>
      ),
      size: 200,
    }),
  ];

  /*=========================
    API DATA
  ==========================*/
  const { data, isLoading, isFetching } = useLogsEmailsGetAll(searchedValue);

  const EMAIL_LOGS = useMemo(() => {
    // Exit function and return default value if there's no data
    if (!data || !data.messages.length || isLoading) {
      // Trigger callback to update the shown logs count
      handleLogsCount(null);

      return [];
    }

    // Sort the data in descending order based on "ID" field value
    const emailLogsDataCopy = [...data.messages].sort((logA, logB) => {
      return logA.id > logB.id ? -1 : 1;
    });

    // Trigger callback to update the shown logs count
    handleLogsCount({ shownCount: data.messages.length, totalCount: data.total });

    return emailLogsDataCopy;
  }, [data]);

  return (
    <>
      {isLoading ? (
        <TableSkeletonPlaceholder modifierClass="table-wrapper table-wrapper--no-shadow table-wrapper--no-overflow" />
      ) : (
        <Table
          data={EMAIL_LOGS}
          noDataMessage="No Email logs data to show"
          columns={EMAILS_TABLE_COLUMNS}
          isRefetching={isFetching}
          paginationPageSize={100}
          modifierClass="table-wrapper--no-shadow"
        />
      )}
    </>
  );
};

export default EmailLogs;
