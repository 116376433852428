// Utilities & Hooks
import { orderBy } from "lodash-es";
import { useEffect, useMemo, useState } from "react";
import { useChatGetAllUserChats } from "../../api/Chat/Chat";
import { ChatsForSpecificUserResponseFields } from "../../api/Chat/interfaces";
import { useUsersGetList } from "../../api/Users/Users";
import { useAuth } from "../../providers/auth-context";
import { useParams } from "react-router-dom";
import { useExtractSearchParameters } from "../../hooks/useExtractSearchParameters";
import handleFullnameCombination from "../../utilities/strings/handleFullnameCombination";

// Components
import ChatActions from "../../components/Chat/ChatActions";
import ChatAvatar from "../../components/Chat/ChatAvatar";
import ChatConversation from "../../components/Chat/Conversation/ChatConversation";
import ChatMessageListItem from "../../components/Chat/ChatMessageListItem";
import ChatMessagesSort from "../../components/Chat/ChatMessagesSort";
import SkeletonChatMessageListItem from "../../components/Chat/Skeleton/SkeletonChatMesageListItem";
import CustomScrollbars from "../../components/CustomScrollbars/CustomScrollbars";
import DropdownSearchable from "../../components/Dropdown/DropdownSearchable";
import Loader from "../../components/Loader/Loader";
import PermissionCheckComponentWrapper from "../../components/Wrappers/PermissionCheckComponentWrapper";

// Constants
import { CHATS_SORTING_OPTIONS } from "../../components/Chat/constants";

// Assets
import { MdClose as CloseIcon } from "react-icons/md";
import { LuPanelRightOpen as ChatsListIcon } from "react-icons/lu";

// Interfaces
import { ChatsSortBy } from "../../components/Chat/interfaces";
import { DropdownItem } from "../../components/Dropdown/interfaces";

const Chats = () => {
  const { user } = useAuth();
  const { id } = useParams();

  /*============================
    LIST OF COMPANY USERS
  =============================*/
  const { data: companyUsers, isLoading: companyUsersLoading } = useUsersGetList();

  const USERS = useMemo(() => {
    if (!companyUsers || !companyUsers.length || companyUsersLoading) return [];

    // Map the received users list into list of dropdown items
    const mappedUsers: DropdownItem[] = companyUsers
      .filter(user => user.status === "active")
      .map(user => {
        return { text: handleFullnameCombination(user), value: user.id };
      });

    const filteredUsers: DropdownItem[] = mappedUsers
      .filter(mappedUser => {
        return mappedUser.value !== user.id;
      })
      .sort((userA, userB) => {
        return userA.text.toLowerCase() > userB.text.toLowerCase() ? 1 : -1;
      });

    // Final version of the users list to be used in the dropdown menu
    // with the currently logged in user selection option appended to the mapped users
    const usersListFinal: DropdownItem[] = [
      { text: handleFullnameCombination(user), value: user.id },
      ...filteredUsers,
    ];

    return usersListFinal;
  }, [companyUsers]);

  /*================================
    LIST OF CHATS FOR SELECTED USER
  =================================*/
  const [selectedUserID, setSelectedUserID] = useState<number>(0);
  const [sortBy, setSortBy] = useState<ChatsSortBy>("most_recent");

  const { data: userChats, isLoading: userChatsLoading } = useChatGetAllUserChats(selectedUserID);

  const USER_CHATS = useMemo(() => {
    // Exit function if there's no data available (yet)
    if (!userChats || !userChats.length || userChatsLoading) return [];

    // Make a copy of the original data before manipulating it
    let chatsData = [...userChats];

    // Filter out the data that will be presented in the dropdown menu
    if (sortBy === ("blocked" || "unread")) {
      chatsData = chatsData.filter((chat: any) => {
        return chat[CHATS_SORTING_OPTIONS[sortBy].fieldname];
      });
    }

    // Order the chat messages data based on selected option
    chatsData = orderBy(
      chatsData,
      [CHATS_SORTING_OPTIONS[sortBy].fieldname],
      [CHATS_SORTING_OPTIONS[sortBy].direction],
    );

    return chatsData;
  }, [userChats, sortBy]);

  // Preselect the ID of the user for who we want to see the chats
  useEffect(() => {
    // If there's a valid "id" route parameter received,
    // meaning user was redirected to this page by clicking "View Messages"
    // from the Users page, then pre-select that user
    // Otherwise, use the ID of the currently logged in user
    if (id !== undefined) {
      setSelectedUserID(parseInt(id));
    } else {
      setSelectedUserID(user.id);
    }
  }, [user.id, id]);

  /*=================================
    SELECT A SPECIFIC CHAT

    - Automatically pre-select the first
      available chat from the list
    - Allow user selection of which chat
      to be opened
  ==================================*/
  const [selectedChatID, setSelectedChatID] = useState<number | null>(null);

  /*

    Pre-select the first available chat in the list

    =================================================

    Anytime the "list" of chats, or the currently selected "chat" is updated / refreshed,
    try to preselect the first available chat in the list.
    
    If there's already a chat with a valid ID selected, prevent any preselection.
    If there are no chats available at all, prevent any preselection.
    If there's no chat already selected (the "selectedChatID" state is "null"),
    and there's a list of chats that we can work with, then preselect the first chat.
  
  */
  useEffect(() => {
    // Exit function if there's a selected chat already
    if (selectedChatID) return;

    // Exit function if there are no available chats to work with
    if (!USER_CHATS.length) return;

    const preselectedChatID: number = USER_CHATS[0].id;
    setSelectedChatID(preselectedChatID);
  }, [USER_CHATS, selectedChatID]);

  const handleChatSelection = (chatID: number) => {
    setSelectedChatID(chatID);

    // Whenever a chat selection is made, close the drawer for the user chats list
    setIsChatsListOpen(false);
  };

  /*==========================
    SELECTED CHAT DETAILS
  ===========================*/
  const [selectedChatDetails, setSelectedChatDetails] =
    useState<ChatsForSpecificUserResponseFields | null>(null);

  // Extract the details for the selected chat
  useEffect(() => {
    if (!selectedChatID) return;

    const selectedChat: ChatsForSpecificUserResponseFields | undefined = USER_CHATS.find(chat => {
      return chat.id === selectedChatID;
    });

    // Exit function if there's no such chat to be found
    if (!selectedChat) return;

    // Update the state for the currently selected
    // chat with the details of the already fetched, and matching, chat
    setSelectedChatDetails(selectedChat);
  }, [selectedChatID, USER_CHATS]);

  /*==============================
    EXTRACT USER INFO

    Extracts the currently active user's name
  ===============================*/
  const [selectedUserName, setSelectedUserName] = useState<string>("");

  useEffect(() => {
    // Exit function if the logged in user's data is not available yet
    if (!user.id) return;

    // If there's already a valid selected chat ID,
    // and the value for the selected user has changed in the meantime,
    // then reset the selected chat to `null` so it will trigger default functionality
    if (selectedChatID) setSelectedChatID(null);

    // If there's a valid selected user, extract the name for that specific user.
    // Otherwise extract the name for the currently logged in user.
    if (selectedUserID) {
      const matchingUser = USERS.find(user => user.value === selectedUserID);

      // If a matching selected user is found in the list, extract the name
      if (matchingUser) setSelectedUserName(matchingUser.text);
    } else {
      const loggedInUserName: string = handleFullnameCombination(user);
      setSelectedUserName(loggedInUserName);
    }
  }, [selectedUserID, user.id]);

  /*==========================================
    SELECTED USER SEARCH PARAMETERS HANDLING
  ===========================================*/
  const [searchParametersObject, setSearchParametersObject] = useExtractSearchParameters();

  // Pre-select the user from the dropdown menu based on received "user_id" search parameter
  useEffect(() => {
    if (searchParametersObject.user_id || USERS.length > 0) {
      // Convert the received search parameter to a number
      const parsedUserID = parseInt(searchParametersObject.user_id);

      // If invalid number (NaN) is received after trying to parse the received search parameter, exit function
      if (!parsedUserID) return;

      // Pre-select the user whose ID was received as a search parameter
      setSelectedUserID(parsedUserID);
    }
  }, [USERS]);

  /*==========================================
    USER CHATS LISTING DRAWER
  ===========================================*/
  const [isChatsListOpen, setIsChatsListOpen] = useState<boolean>(false);

  return (
    <div className="chat-page__wrapper">
      <div className="chat-page">
        <div className={`chat-page__list ${isChatsListOpen ? "chat-page__list--open" : ""}`}>
          <div className="d-flex justify-content-between align-items-start">
            <div className="w--100 d-flex flex-column flex-lg-row justify-content-between align-items-start align-items-lg-center mb--10">
              <h1 className="mb--0">
                <strong>All Messages</strong>
              </h1>

              <ChatMessagesSort
                sortBy={sortBy}
                handleChatMessagesSorting={sort => setSortBy(sort)}
              />
            </div>

            <CloseIcon
              className="chat-page__list__drawer__close"
              onClick={() => setIsChatsListOpen(false)}
            />
          </div>

          <PermissionCheckComponentWrapper permissions={["sms_read_company_messages"]}>
            <DropdownSearchable
              placeholder="Choose User"
              items={USERS}
              size="full"
              modifierClass="mb--15"
              isLoading={companyUsersLoading}
              disabled={companyUsersLoading || !USERS.length}
              maxScrollableHeight="350px"
              preselectedItemValue={selectedUserID}
              allowDeselection={false}
              handleItemSelected={user => {
                setSelectedUserID(user.value as number);
                setSearchParametersObject({ ...searchParametersObject, user_id: user.value });
              }}
            />
          </PermissionCheckComponentWrapper>

          <hr />

          <CustomScrollbars maxHeight="calc(100vh - 300px)" hideAxis="x">
            {userChatsLoading
              ? [...Array(5).keys()].map((_, skeletonIndex: number) => (
                  <SkeletonChatMessageListItem
                    key={`skeleton-chat-messages-dropdown-${skeletonIndex}`}
                  />
                ))
              : USER_CHATS.map((chat: ChatsForSpecificUserResponseFields) => (
                  <ChatMessageListItem
                    key={chat.id}
                    id={chat.id}
                    photo={chat.applicant.photo}
                    full_name={chat.applicant.full_name}
                    last_message={chat.last_message.content}
                    timestamp={chat.last_message.timestamp * 1000}
                    is_read={chat.last_message.is_read}
                    is_blocked={chat.is_blocked}
                    modifierClass={`chat-message-list-item--chats-page ${
                      selectedChatID === chat.id ? "chat-message-list-item--active" : ""
                    }`}
                    hasActionsMenu={false}
                    handleChatMessageListItem={handleChatSelection}
                  />
                ))}
          </CustomScrollbars>
        </div>

        <div className="chat-page__conversation">
          {userChatsLoading || (!selectedChatDetails && USER_CHATS.length) ? (
            <Loader size="lg" modifierWrapper="mx--auto" />
          ) : !USER_CHATS.length || !selectedChatDetails ? (
            <div className="d-flex flex-column align-items-center justify-content-center w--100">
              <h3 className="mb--20">No conversations found.</h3>

              <div
                className="d-flex d-lg-none align-items-center"
                onClick={() => setIsChatsListOpen(!isChatsListOpen)}
              >
                <ChatsListIcon className="chat-page__list__drawer__open mr--10i" />
                <span>Open Chats list</span>
              </div>
            </div>
          ) : (
            <div className="chat-page__conversation__content">
              <div className="chat-page__conversation__header">
                <div className="chat-page__conversation__header__details">
                  <ChatsListIcon
                    className="chat-page__list__drawer__open"
                    onClick={() => setIsChatsListOpen(!isChatsListOpen)}
                  />

                  <ChatAvatar
                    size="lg"
                    name={selectedChatDetails.applicant.full_name ?? "N/A"}
                    image_url={selectedChatDetails.applicant.photo ?? null}
                    status={selectedChatDetails.is_blocked ? "blocked" : "active"}
                  />

                  <h5>
                    <strong>{selectedChatDetails.applicant.full_name}</strong>
                  </h5>
                </div>

                {/* 
                  Only display the chat actions if the 
                  logged in user matches the selected user from the list,
                  preventing block/unblock actions in the name of other users
                */}
                {user.id === selectedUserID ? (
                  <ChatActions
                    id={selectedChatDetails.id}
                    is_blocked={selectedChatDetails.is_blocked}
                  />
                ) : null}
              </div>

              <ChatConversation
                chatID={selectedChatID}
                chatConversationDetails={{
                  currentUserName: selectedUserName,
                  chatting_with: {
                    full_name: selectedChatDetails.applicant.full_name,
                    photo: selectedChatDetails.applicant.photo,
                  },
                  is_blocked: selectedChatDetails.is_blocked,
                }}
                selectedUserID={selectedUserID}
                scrollableHeight="calc(100vh - 360px)"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Chats;
