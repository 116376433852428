// Interfaces
import { ApplicationCardHeaderProps } from "./interfaces";

// Components
import ApplicationSBCA from "./ApplicationSBCA";
import ApplicationDateSection from "./ApplicationDateSection";

const ApplicationCardHeader = ({
  id,
  name,
  email,
  registration_date,
  hired_date,
  activeBucket,
  applicantBucket,
  sbca,
  sbca_report_identifier,
  sbca_request_date,
  isSelected = false,
  hasDateSection,
  handleApplicationSelection,
}: ApplicationCardHeaderProps) => {
  return (
    <div className="applications__header">
      <div className={`d-flex ${activeBucket !== "Hired" ? "align-items-center" : ""}`}>
        <div>
          <input
            type="checkbox"
            value={id}
            checked={isSelected}
            className="cursor--clickable"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              const value = event.target.value;
              handleApplicationSelection(typeof value === "string" ? parseInt(value) : value);
            }}
          />
        </div>

        {/* Check wether we should include the application date section */}
        {hasDateSection ? (
          <ApplicationDateSection
            active_bucket={activeBucket}
            applicant_hired_date={hired_date}
            applicant_name={name}
            application_id={id}
            registration_date={registration_date}
            applicant_bucket={applicantBucket}
          />
        ) : null}
      </div>

      <ApplicationSBCA
        id={id}
        name={name}
        email={email}
        sbca={sbca}
        sbca_report_identifier={sbca_report_identifier}
        sbca_request_date={sbca_request_date}
      />
    </div>
  );
};

export default ApplicationCardHeader;
