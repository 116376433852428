// Components
import Banner from "../../components/Banner/Banner";
import Button from "../../components/Button/Button";
import ContentHeader from "../../components/Content/ContentHeader";
import GeneralSection from "../../components/CareerPages/Form/GeneralSection";
import HighlightsSection from "../../components/CareerPages/Form/HighlightsSection";
import FeaturedHighlightsSection from "../../components/CareerPages/Form/FeaturedHighlightsSection";
import BenefitsSection from "../../components/CareerPages/Form/BenefitsSection";
import CareerPagesSkeleton from "./CareerPagesSkeleton";
import PermissionCheckComponentWrapper from "../../components/Wrappers/PermissionCheckComponentWrapper";

// Assets
import { BsInfoCircle as InfoIcon } from "react-icons/bs";

// Forimk
import { Form, Formik, FormikProps } from "formik";

// Schemas
import {
  CAREER_PAGES_FORM_NOT_ENABLED,
  CAREER_PAGES_FORM_ENABLED,
} from "../../schemas/CareerPages";

// Utilities & Hooks
import { useEffect, useState, useRef } from "react";
import {
  useCareerPagesGetDetails,
  useCareerPagesUpdateDetails,
} from "../../api/CareerPages/CareerPages";
import { useAuth } from "../../providers/auth-context";
import { handleCareerPagesOrdinalityDataSorting } from "./utils/handleCareerPagesOrdinalityDataSorting";
import { handleCareerPagesFilteredHighlightsData } from "./utils/handleCareerPagesFilteredHighlightsData";
import { handleFormikScrollToFirstErrorManualTrigger } from "../../utilities/forms/formik";
import useErrorReporting from "../../hooks/useErrorReporting";

// Interfaces
import { CareerPagesFormStateFields } from "./interfaces";
import { CareerPageDetailsUpdateRequestFields } from "../../api/CareerPages/interfaces";
import { Link } from "react-router-dom";

const CareerPages = () => {
  const { user } = useAuth();
  const formikReference = useRef<FormikProps<CareerPagesFormStateFields>>(null);
  const errorReporting = useErrorReporting();

  /*===================
    FORM STATE
  ====================*/
  const [careerPagesFormState, setCareerPagesFormState] = useState<CareerPagesFormStateFields>({
    enabled: false,
    about_us_feature_image: null,
    careers_feature_image: null,
    team_image: null,
    highlights: [],
    benefits: [],
    sub_header: "",
    about_us: "",
    embed_video: "",
    featured: [],
    meta: {
      team_image_url: null,
    },
  });

  /*==============================
    FETCH CAREER PAGES DETAILS
    AND PRE-POPULATE THE FORM
  ===============================*/
  const { data, isLoading } = useCareerPagesGetDetails();

  useEffect(() => {
    // Exit function if there's no data or request is still ongoing
    if (!data || isLoading) return;

    /*==========================
      HIGHLIGHTS

      - Sort the section's items based on ordinality
    ===========================*/
    const SORTED_HIGHLIGHTS = handleCareerPagesOrdinalityDataSorting(data.highlights);

    /*==========================
      BENEFITS

      - Sort the section's items based on ordinality
    ===========================*/
    const SORTED_BENEFITS = handleCareerPagesOrdinalityDataSorting(data.benefits);

    /*==========================
      FEATURED HIGHLIGHTS

      - Sort the section's items based on ordinality
      - Map the existing objects so the 'image' field won't be populated with data
      - If there are less than 3 featured highlights available
      in the response from the API, populate with default data. Maximum of 3.
    ===========================*/
    const SORTED_FEATURED_HIGHLIGHTS = handleCareerPagesOrdinalityDataSorting(data.featured).map(
      featured => {
        return { ...featured, image_initial_value: featured.image, image: "" };
      },
    );

    // Fill the featured highlights array if there are less than 3 objects
    while (SORTED_FEATURED_HIGHLIGHTS.length < 3) {
      SORTED_FEATURED_HIGHLIGHTS.push({
        image: null,
        image_initial_value: null,
        headline_1: "",
        headline_2: "",
        body: "",
        id: null,
      });
    }

    // Map the data received from the API into usable format
    const mappedData: CareerPagesFormStateFields = {
      ...careerPagesFormState,
      enabled: data.enabled,
      sub_header: data.sub_header ?? "",
      about_us: data.about_us ?? "",
      embed_video: data.embed_video ?? "",
      highlights: SORTED_HIGHLIGHTS,
      benefits: SORTED_BENEFITS,
      featured: SORTED_FEATURED_HIGHLIGHTS,
      meta: {
        ...careerPagesFormState.meta,
        team_image_url: data.team_image as string | null,
      },
    };

    // Update the state for the form fields and trigger form reinitialization
    setCareerPagesFormState(mappedData);
  }, [data]);

  /*==============================
    SAVE CAREER PAGE DETAILS

    NOTE: Currently triggers same action as "Publish"
  ===============================*/
  const [careerPageSaveAction, setCareerPageSaveAction] = useState<boolean>(false);

  const handleCareerPageSave = async () => {
    if (!formikReference || !formikReference.current) return;

    setCareerPageSaveAction(true);

    try {
      // Manually trigger the Formik's form submission
      // so we can get proper form validation
      await formikReference.current.submitForm();

      // Scroll the page to the first error (if any)
      // that shows up right after form submission
      handleFormikScrollToFirstErrorManualTrigger(formikReference.current.errors);
    } finally {
      setCareerPageSaveAction(false);
    }
  };

  /*==============================
    PUBLISH CAREER PAGE

    NOTE: Currently triggers same action as "Save"
  ===============================*/
  const [careerPagePublishAction, setCareerPagePublishAction] = useState<boolean>(false);

  const handleCareerPagePublish = async () => {
    if (!formikReference || !formikReference.current) return;

    setCareerPagePublishAction(true);

    try {
      // Manually trigger the Formik's form submission
      // so we can get proper form validation
      await formikReference.current.submitForm();

      // Scroll the page to the first error (if any)
      // that shows up right after form submission
      handleFormikScrollToFirstErrorManualTrigger(formikReference.current.errors);
    } finally {
      setCareerPagePublishAction(false);
    }
  };

  /*==============================
    UPDATE THE CAREER PAGE DETAILS
  ===============================*/
  const updateCareerPage = useCareerPagesUpdateDetails();

  const handleCareerPageDetailsUpdate = async (values: CareerPagesFormStateFields) => {
    try {
      /*=====================================
        FILTER INVALID "FEATURED HIGHLIGHTS"

        Only the featured highlights that have a valid ID value OR
        featured highlights where all the fields are populated with value
        should be included in the PAYLOAD object 
      =======================================*/
      const filteredHighlights = handleCareerPagesFilteredHighlightsData(values.featured);

      /*=====================================
        CONSTRUCT THE FINAL REQUEST PAYLOAD
      =======================================*/
      const REQUEST_PAYLOAD: CareerPageDetailsUpdateRequestFields = {
        ...values,

        // Only include the images if they have a valid value
        ...(values.about_us_feature_image && {
          about_us_feature_image: values.about_us_feature_image,
        }),
        ...(values.careers_feature_image && {
          careers_feature_image: values.careers_feature_image,
        }),
        featured: filteredHighlights,
        team_image: values.team_image === null ? "" : values.team_image,
      };

      await updateCareerPage.mutateAsync(REQUEST_PAYLOAD);

      // If request to the API is successful,
      // update the internal state of the form to mark
      // the career pages page as "enabled"
      setCareerPagesFormState({
        ...values,
        enabled: true,
      });
    } catch (error) {
      errorReporting("Failed updating Career Page details.", error, { ...values });
    }
  };

  return (
    <div className="container py--25">
      {isLoading ? (
        <CareerPagesSkeleton />
      ) : (
        <Formik
          initialValues={careerPagesFormState}
          validationSchema={
            careerPagesFormState.enabled ? CAREER_PAGES_FORM_ENABLED : CAREER_PAGES_FORM_NOT_ENABLED
          }
          enableReinitialize
          innerRef={formikReference}
          onSubmit={handleCareerPageDetailsUpdate}
        >
          {({ values, errors, setFieldValue }) => (
            <Form>
              <ContentHeader title="Career Pages" modifierClass="mb--20">
                <div className="d-flex flex-column flex-md-row justify-content-md-end align-items-center w--100">
                  <span className="txt--md fw--medium mr--20 mr--md--0 mb--md--10 w--md-100">
                    URL:{" "}
                    <a
                      href={`${import.meta.env.VITE_CAREER_PAGES_URL}/${user.active_company.slug}`}
                      className="txt--blue"
                      target="_blank"
                    >
                      {import.meta.env.VITE_CAREER_PAGES_URL}/{user.active_company.slug}
                    </a>
                  </span>
                  <a
                    href={`${import.meta.env.VITE_CAREER_PAGES_URL}/${user.active_company.slug}`}
                    className="btn btn--fluid btn--secondary mr--10 mr--md--0 mb--md--10 w--md-100"
                    target="_blank"
                  >
                    Preview
                  </a>

                  <PermissionCheckComponentWrapper permissions={["career_page_edit"]}>
                    <Button
                      modifierClass="btn--fluid btn--primary--light mr--10 mr--md--0 mb--md--10 w--md-100"
                      isLoading={careerPageSaveAction}
                      isDisabled={careerPageSaveAction}
                      type="button"
                      onClick={handleCareerPageSave}
                    >
                      Save
                    </Button>

                    <Button
                      modifierClass="btn--fluid btn--primary w--md-100"
                      isLoading={careerPagePublishAction}
                      isDisabled={careerPagePublishAction}
                      type="button"
                      onClick={handleCareerPagePublish}
                    >
                      Publish
                    </Button>
                  </PermissionCheckComponentWrapper>
                </div>
              </ContentHeader>

              <Banner
                text="Use the sections below to give your potential applicants an opportunity to get to know
              your company as they apply for your current positions."
                icon={<InfoIcon />}
              />

              <Link to="/career/pages/graphics/" className="btn btn--fluid btn--primary mb--30">
                Edit Company Logo, Apply Image, Banner Image, or Full Graphic Image
              </Link>

              <GeneralSection apiData={data} formValues={values} setFieldValue={setFieldValue} />

              {/* HIGHLIGHTS SECTION */}
              <HighlightsSection highlights={values.highlights} />

              {/* FEATURED HIGHLIGHTS SECTION */}
              <FeaturedHighlightsSection
                apiData={data}
                errors={errors}
                handleFormFieldsClearing={(activeTab: string) => {
                  setFieldValue(`featured.${activeTab}.image`, null);
                  setFieldValue(`featured.${activeTab}.image_initial_value`, null);
                  setFieldValue(`featured.${activeTab}.headline_1`, "");
                  setFieldValue(`featured.${activeTab}.headline_2`, "");
                  setFieldValue(`featured.${activeTab}.body`, "");
                  setFieldValue(`featured.${activeTab}.id`, null);
                }}
              />

              {/* BENEFITS SECTION */}
              <BenefitsSection benefits={values.benefits} />

              <hr />

              <div className="d-flex flex-column flex-md-row justify-content-md-end align-items-center w--100">
                <span className="txt--md fw--medium mr--20 mr--md--0 mb--md--10 w--md-100">
                  URL:{" "}
                  <a
                    href={`${import.meta.env.VITE_CAREER_PAGES_URL}/${user.active_company.slug}`}
                    className="txt--blue"
                    target="_blank"
                  >
                    {import.meta.env.VITE_CAREER_PAGES_URL}/{user.active_company.slug}
                  </a>
                </span>
                <a
                  href={`${import.meta.env.VITE_CAREER_PAGES_URL}/${user.active_company.slug}`}
                  className="btn btn--fluid btn--secondary mr--10 mr--md--0 mb--md--10 w--md-100"
                  target="_blank"
                >
                  Preview
                </a>

                <PermissionCheckComponentWrapper permissions={["career_page_edit"]}>
                  <Button
                    modifierClass="btn--fluid btn--primary--light mr--10 mr--md--0 mb--md--10 w--md-100"
                    isLoading={careerPageSaveAction}
                    isDisabled={careerPageSaveAction}
                    type="button"
                    onClick={handleCareerPageSave}
                  >
                    Save
                  </Button>

                  <Button
                    modifierClass="btn--fluid btn--primary w--md-100"
                    isLoading={careerPagePublishAction}
                    isDisabled={careerPagePublishAction}
                    type="button"
                    onClick={handleCareerPagePublish}
                  >
                    Publish
                  </Button>
                </PermissionCheckComponentWrapper>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
};

export default CareerPages;
