// Utilities & Hooks
import { useEffect } from "react";

// Components
import { Link } from "react-router-dom";
import OnlineApplicationFooter from "../../components/OnlineApplications/OnlnieApplicationFooter";

// Assets
import FCHLogo from "../../assets/images/fch-full-logo.png";

const OnlnieApplicationPolicy = () => {
  // Scroll to the top of the page on page load
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="online-application-policy">
      <div className="container">
        <Link to="/login/">
          <img
            src={FCHLogo}
            className="online-application-thank-you__logo"
            title="FirstChoice Hiring logo"
          />
        </Link>

        <h3>FirstChoice Privacy Policy</h3>

        <p className="online-application-policy__text online-application-policy__text--bold">
          We respect your private information.
        </p>

        <h6 className="online-application-policy__text online-application-policy__text--bold">
          What information do we collect?
        </h6>
        <p className="online-application-policy__text">
          We collect information from you when you register on our site, apply for employment,
          subscribe to our newsletter, respond to a survey, fill out a form or request to be
          contacted.
        </p>
        <p className="online-application-policy__text">
          When ordering or registering on our site, as appropriate, you may be asked to enter your:
          name, e-mail address, mailing address, phone number, social or networking site url, or
          company name. You may, however, visit our site anonymously.
        </p>

        <h6 className="online-application-policy__text online-application-policy__text--bold">
          What do we use your information for?
        </h6>
        <p className="online-application-policy__text">
          Any of the information we collect from you may be used in one of the following ways:
        </p>
        <ul>
          <li className="online-application-policy__text">
            To personalize your experience (your information helps us to better respond to your
            individual needs)
          </li>
          <li className="online-application-policy__text">
            To improve our website (we continually strive to improve our website offerings based on
            the information and feedback we receive from you)
          </li>
          <li className="online-application-policy__text">
            To improve customer service (your information helps us to more effectively respond to
            your customer service requests and support needs)
          </li>
          <li className="online-application-policy__text">
            To facilitate employment applications and process required information
          </li>
          <li className="online-application-policy__text">
            To administer a contest, promotion, survey or other site feature
          </li>
          <li className="online-application-policy__text">To send periodic emails</li>
        </ul>
        <p className="online-application-policy__text">
          Note: If at any time you would like to unsubscribe from receiving future emails, we
          include detailed unsubscribe instructions at the bottom of each email.
        </p>

        <h6 className="online-application-policy__text online-application-policy__text--bold">
          How do we protect your information?
        </h6>
        <p className="online-application-policy__text">
          We implement a variety of security measures to maintain the safety of your personal
          information when you enter, submit, or access your personal information.
        </p>

        <h6 className="online-application-policy__text online-application-policy__text--bold">
          Do we use cookies?
        </h6>
        <p className="online-application-policy__text">
          Yes (Cookies are small files that a site or its service provider transfers to your
          computer's hard drive through your Web browser (if you allow) that enables the sites or
          service providers systems to recognize your browser and capture and remember certain
          information.
        </p>
        <p className="online-application-policy__text">
          We use cookies to understand and save your preferences for future visits, keep track of
          advertisements and compile aggregate data about site traffic and site interaction so that
          we can offer better site experiences and tools in the future. We may contract with
          third-party service providers to assist us in better understanding our site visitors.
          These service providers are not permitted to use the information collected on our behalf
          except to help us conduct and improve our business.
        </p>

        <h6 className="online-application-policy__text online-application-policy__text--bold">
          Do we disclose any information to outside parties?
        </h6>
        <p className="online-application-policy__text">
          We do not sell, trade, or otherwise transfer to outside parties your personally
          identifiable information. This does not include trusted third parties who assist us in
          operating our website, conducting our business, or servicing you, so long as those parties
          agree to keep this information confidential. We may also release your information when we
          believe release is appropriate to comply with the law, enforce our site policies, or
          protect ours or others rights, property, or safety. However, non-personally identifiable
          visitor information may be provided to other parties for marketing, advertising, or other
          uses.
        </p>

        <h6 className="online-application-policy__text online-application-policy__text--bold">
          Third party links.
        </h6>
        <p className="online-application-policy__text">
          Occasionally, at our discretion, we may include or offer third party products or services
          on our website. These third party sites have separate and independent privacy policies. We
          therefore have no responsibility or liability for the content and activities of these
          linked sites. Nonetheless, we seek to protect the integrity of our site and welcome any
          feedback about these sites.
        </p>

        <h6 className="online-application-policy__text online-application-policy__text--bold">
          California Online Privacy Protection Act Compliance.
        </h6>
        <p className="online-application-policy__text">
          Because we value your privacy we have taken the necessary precautions to be in compliance
          with the California Online Privacy Protection Act. We therefore will not distribute your
          personal information to outside parties without your consent.
        </p>

        <h6 className="online-application-policy__text online-application-policy__text--bold">
          Childrens Online Privacy Protection Act Compliance.
        </h6>
        <p className="online-application-policy__text">
          We are in compliance with the requirements of COPPA (Childrens Online Privacy Protection
          Act), we do not collect any information from anyone under 13 years of age. Our website,
          products and services are all directed to people who are at least 13 years old or older.
        </p>

        <h6 className="online-application-policy__text online-application-policy__text--bold">
          Your Consent
        </h6>
        <p className="online-application-policy__text">
          By using our site, you consent to our websites privacy policy.
        </p>

        <h6 className="online-application-policy__text online-application-policy__text--bold">
          Changes to our Privacy Policy.
        </h6>
        <p className="online-application-policy__text mb--40">
          We may make changes to our privacy policy at our own discretion at any time. We are not
          required to, and will not, notify you of any changes made to this privacy policy.
        </p>

        <OnlineApplicationFooter />
      </div>
    </div>
  );
};

export default OnlnieApplicationPolicy;
