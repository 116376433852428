import { Variants } from "framer-motion";

/*============================
  FRAMER: HEADER
==============================*/
export const FRAMER_HEADER_ANIMATION_COMMON: Variants = {
  initial: {
    opacity: 0,
    translateY: "100px",
  },
  animate: {
    opacity: 1,
    translateY: "10px",
  },
  exit: {
    opacity: 0,
    translateY: "100px",
  },
};

export const FRAMER_HEADER_ANIMATION_WITH_OFFSET: Variants = {
  initial: {
    ...FRAMER_HEADER_ANIMATION_COMMON.initial,
    translateX: "-50%",
    right: "unset",
    left: "50%",
  },
  animate: {
    ...FRAMER_HEADER_ANIMATION_COMMON.animate,
    translateX: "-50%",
    right: "unset",
    left: "50%",
  },
  exit: {
    ...FRAMER_HEADER_ANIMATION_COMMON.exit,
    translateX: "-50%",
    right: "unset",
    left: "50%",
  },
};

export const FRAMER_HEADER_TRANSITIONS = {
  duration: 0.5,
  type: "spring",
};

/*============================
  FRAMER: DROPDOWNS
==============================*/
export const FRAMER_DROPDOWN_ANIMATION: Variants = {
  initial: props => {
    return {
      opacity: 0,
      translateY: "50px",
      translateX: props?.hasSideLabel ? 0 : "-50%",
      zIndex: 999,
    };
  },
  animate: props => {
    return {
      opacity: 1,
      translateY: 0,
      translateX: props?.hasSideLabel ? 0 : "-50%",
      zIndex: 999,
    };
  },
  exit: props => {
    return {
      opacity: 0,
      translateY: "50px",
      translateX: props?.hasSideLabel ? 0 : "-50%",
      zIndex: 999,
    };
  },
};

export const FRAMER_DROPDOWN_TOP_ORIENTATION_ANIMATION: Variants = {
  initial: {
    opacity: 0,
    translateY: "-50px",
    translateX: "-50%",
  },
  animate: {
    opacity: 1,
    translateY: 0,
    translateX: "-50%",
  },
  exit: {
    opacity: 0,
    translateY: "-50px",
    translateX: "-50%",
  },
};

export const FRAMER_DROPDOWN_ACTIONS_ANIMATION: Variants = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
};

/*============================
  FRAMER: SCHEDULE MEETING
==============================*/
export const FRAMER_SCHEDULE_MEETING_ANIMATION: Variants = {
  enter: {
    opacity: 0,
  },
  center: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
  },
};

/*============================
  FRAMER: APPLICATIONS 
  SELECTION INDICATOR
==============================*/
export const FRAMER_SELECTION_INDICATOR: Variants = {
  initial: { opacity: 0, height: 0, y: "-80px", position: "relative", zIndex: 0 },
  animate: {
    opacity: 1,
    height: "50px",
    y: 0,
    position: "relative",
    zIndex: 0,
  },
  exit: {
    height: 0,
    opacity: 0,
    y: "-80px",
    position: "relative",
    zIndex: 0,
  },
};
