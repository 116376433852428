import { useEffect, useRef, useState } from "react";

// Providers
import { useAuth } from "../../providers/auth-context";

// Hooks & Utils
import { LocalStorageActions } from "../../utilities/handleLocalStorage";
import { useAuthenticationLogout } from "../../api/Authentication/Authentication";
import useErrorReporting from "../../hooks/useErrorReporting";
import useOnClickOutside from "../../hooks/useOnClickOutside";

// Interfaces
import { HeaderDropdownGeneralProps } from "./interfaces";

// Statics
import { ACCOUNT_DROPDOWN_MY_ACCOUNT, ACCOUNT_DROPDOWN_SETTINGS } from "./statics";

// Components
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../Button/Button";
import Loader from "../Loader/Loader";
import HeaderDropdownGroup from "./HeaderDropdownGroup";

// Assets
import { FaChevronDown as ChevronIcon } from "react-icons/fa";
import { useOnEscapeKey } from "../../hooks/useOnEscapeKey";
import { motion, AnimatePresence } from "framer-motion";
import {
  FRAMER_HEADER_TRANSITIONS,
  FRAMER_HEADER_ANIMATION_WITH_OFFSET,
} from "../../constants/framer";

const HeaderDropdownAccount: React.FC<HeaderDropdownGeneralProps> = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const errorReporting = useErrorReporting();

  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);

  /*======================
    Close dropdown on click outside
  =======================*/
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  useOnClickOutside(dropdownRef, () => setIsDropdownOpen(false));

  /*================================
    LOGOUT THE USER

    Logs out the user out of the application,
    removes related saved fields in local storage and
    updates the authentication context
  =================================*/
  const authenticationLogout = useAuthenticationLogout();

  const handleLogout = async () => {
    try {
      await authenticationLogout.mutateAsync();

      // Remove the chats that were stored in local storage
      LocalStorageActions.removeItem("fch-chats");

      // Once successfully redirected, clear the location's state
      navigate("/login/", { state: undefined });
    } catch (error) {
      errorReporting("Failed logging out the user", error, { user_id: user.id });
    }
  };

  /*============================
    CLOSE MENU ON ROUTE CHANGE
  =============================*/
  const location = useLocation();

  useEffect(() => {
    setIsDropdownOpen(false);
  }, [location.pathname]);

  /*=======================
    CLOSE ON "ESCAPE" KEY
  ========================*/
  useOnEscapeKey(dropdownRef, () => setIsDropdownOpen(false));

  return (
    <div ref={dropdownRef} className="header-dropdown header-dropdown--account" tabIndex={0}>
      <div
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
        className={`header-dropdown__body ${isDropdownOpen ? "header-dropdown__body--active" : ""}`}
        data-testid="components:dropdown-header-account"
      >
        {user.first_name ? (
          <span>{user.first_name}</span>
        ) : (
          <span className="d-flex align-items-center">
            Loading...
            <Loader size="xs" modifierWrapper="ml--10" />
          </span>
        )}

        <ChevronIcon className="header-dropdown__chevron" />
      </div>

      <AnimatePresence>
        {isDropdownOpen && (
          <motion.ul
            key="framer-header-account-dropdown"
            className="header-dropdown__content header-dropdown__content--lg"
            variants={FRAMER_HEADER_ANIMATION_WITH_OFFSET}
            initial="initial"
            animate="animate"
            exit="exit"
            transition={FRAMER_HEADER_TRANSITIONS}
          >
            <HeaderDropdownGroup data={ACCOUNT_DROPDOWN_MY_ACCOUNT} />

            <HeaderDropdownGroup data={ACCOUNT_DROPDOWN_SETTINGS} />

            {/*LOG OUT*/}
            <li className="header-dropdown__single">
              <Button
                className="header-dropdown__link"
                isDisabled={authenticationLogout.isLoading}
                isLoading={authenticationLogout.isLoading}
                onClick={handleLogout}
              >
                Logout
              </Button>
            </li>
          </motion.ul>
        )}
      </AnimatePresence>
    </div>
  );
};
export default HeaderDropdownAccount;
