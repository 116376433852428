import React, { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useExtractSearchParameters } from "../hooks/useExtractSearchParameters";
import { PRODUCT_TOURS_DATA } from "../components/ProductTour/constants";
import Modal from "../components/Modal/Modal";
import Button from "../components/Button/Button";
import { ProductTourDetails, ProductTourIds } from "../components/ProductTour/interfaces";
import { useMarkFinishTour } from "../api/Tours/Tours";
import { useAuth } from "./auth-context";
import useErrorReporting from "../hooks/useErrorReporting";

interface TourContextProps {
  activeTour: ProductTourDetails | null;
  isTourRunning: boolean;
  tourCurrentStep: number;
  tourCurrentStepName: string;
  handleTourStart: (tourId: ProductTourIds) => void;
  handlePromptTourEnd: () => void;
  handleTourEnd: () => void;
  handleTourNextStep: (transitionTimeout: number, callback?: () => void) => void;
  handleTourPreviousStep: (transitionTimeout: number, callback?: () => void) => void;
  handlePrepStep: (stepName: string, callback: () => void, timeout: number) => void;
}

const TourContext = createContext<TourContextProps>({
  activeTour: null,
  isTourRunning: false,
  tourCurrentStep: 0,
  tourCurrentStepName: "",
  handleTourStart: () => undefined,
  handlePromptTourEnd: () => undefined,
  handleTourEnd: () => undefined,
  handleTourNextStep: () => undefined,
  handleTourPreviousStep: () => undefined,
  handlePrepStep: () => undefined,
});

const TourContextWrapper = ({ children }: { children: React.ReactNode }) => {
  // for some reason we must include this
  // TODO: check if it causes issues with something else - very important
  window.global = globalThis;

  // Handle tour start on searchparams 'activeTour' query presence
  const [searchParametersObject, setSearchParametersObject] = useExtractSearchParameters();
  const { user } = useAuth();

  useEffect(() => {
    // Use search query param to find the corresponding tour object
    const foundTour =
      PRODUCT_TOURS_DATA.find(tour => tour.tourId === searchParametersObject.activeTour) ?? null;
    if (!foundTour) return;

    if (foundTour.tourId === activeTour?.tourId) return;
    // Set found tour data as active tour state, set running state to true
    handleTourStart(foundTour.tourId);
  }, [searchParametersObject]);

  // Manual handling of the tours
  const [activeTour, setActiveTour] = useState<ProductTourDetails | null>(null);
  const [isTourRunning, setIsTourRunning] = useState<boolean>(false);
  const [tourCurrentStep, setTourCurrentStep] = useState<number>(0);

  const [showCancelTourModal, setShowCancelTourModal] = useState(false);

  // Sync between step index & step name state
  const tourCurrentStepName = useMemo(() => {
    // find the name of the step from the current tour
    // corresponding to the the current step index
    const tourStepName: string = activeTour?.steps[tourCurrentStep]?.stepName || "";

    return tourStepName;
  }, [tourCurrentStep]);

  const handleTourStart = (tourId: ProductTourIds) => {
    const tour: ProductTourDetails | undefined = PRODUCT_TOURS_DATA.find(
      tour => tour.tourId === tourId,
    );

    // exit function if no match was found
    if (!tour) return;

    setActiveTour(tour);
    setIsTourRunning(true);

    if (searchParametersObject.activeTour === activeTour?.tourId) return;
    setSearchParametersObject({ ...searchParametersObject, activeTour: tour.tourId });
  };

  // Execute prompt tour end
  const handlePromptTourEnd = () => {
    // Toggle double confirmation modal for ending tour
    setShowCancelTourModal(true);
  };

  // Execute tour end
  const markFinishedTour = useMarkFinishTour();
  const errorReporting = useErrorReporting();

  const handleTourEnd = () => {
    if (!activeTour) return;
    setShowCancelTourModal(false);

    setIsTourRunning(false);
    setActiveTour(null);
    setTourCurrentStep(0);
    delete searchParametersObject.activeTour;
    setSearchParametersObject(searchParametersObject);

    if (!user || !user.unwatched_product_tours.length) return;
    /*============================================
    Check if the current tour is part of the 
    'unwatched tours' in the user object, if so
    send a post request containing the tour id
    to mark it as finished for the current user.
    ============================================*/

    // Try to find the current active tour in the 'unwatched_product_tours' array in the user object
    const matchedForMarking = user.unwatched_product_tours.find(
      unwatchedTour => unwatchedTour.name === activeTour.tourId,
    )?.id;

    // If found, initiate 'mark as finished' post request using the current tour number ID
    if (matchedForMarking) {
      try {
        markFinishedTour.mutateAsync(matchedForMarking);
      } catch (error) {
        errorReporting("Failed marking current tour as finished", error);
      }
    }
  };

  const handleTourNextStep = (transitionTimeout: number = 500, callback?: () => void) => {
    // Prevent any actions if there is no active tour or tour is paused
    if (!activeTour) return;

    // Check if current step is last step, if so dont update counter
    const isLastStepOfTour: boolean = activeTour.steps.length - 1 === tourCurrentStep;

    // In case the tour should be paused between steps (cases of element toggle or animations)
    if (transitionTimeout) {
      setIsTourRunning(false);

      if (callback) callback();
      setTimeout(() => {
        setIsTourRunning(true);

        if (!isLastStepOfTour) setTourCurrentStep(tourStep => tourStep + 1);
      }, transitionTimeout);
    } else {
      if (!isLastStepOfTour) setTourCurrentStep(tourStep => tourStep + 1);

      // if there's some callback to be triggered, do it now
      if (callback) callback();
    }
  };

  const handlePrepStep = (stepName: string, callback: () => void, timeout: number) => {
    // If the current step doesnt match the callee step name, exit
    if (tourCurrentStepName !== stepName) return;

    setIsTourRunning(false);
    setTimeout(() => {
      callback();
      setIsTourRunning(true);
    }, timeout);
  };

  const handleTourPreviousStep = (transitionTimeout: number = 500, callback?: () => void) => {
    // Prevent any actions if there is no active tour or tour is paused
    if (!activeTour || !isTourRunning) return;

    if (transitionTimeout) {
      setIsTourRunning(false);

      if (callback) callback();
      setTimeout(() => {
        setIsTourRunning(true);

        setTourCurrentStep(tourStep => tourStep - 1);
      }, transitionTimeout);
    } else {
      setTourCurrentStep(tourStep => tourStep - 1);

      // if there's some callback to be triggered, do it now
      if (callback) callback();
    }
  };

  // Disable user induced page scrolling in case the tour is running
  // Note: This does not prevent the tour handling context/functionality from scrolling to steps
  useEffect(() => {
    if (isTourRunning) {
      document.body.classList.add("prevent-scroll");
    } else {
      document.body.classList.remove("prevent-scroll");
    }

    return () => document.body.classList.remove("prevent-scroll");
  }, [isTourRunning]);

  return (
    <TourContext.Provider
      value={{
        activeTour,
        isTourRunning,
        tourCurrentStep,
        tourCurrentStepName,
        handleTourStart,
        handlePromptTourEnd,
        handleTourEnd,
        handleTourNextStep,
        handleTourPreviousStep,
        handlePrepStep,
      }}
    >
      {children}
      {showCancelTourModal ? (
        <Modal
          title="End Tour?"
          text={
            <>
              Are you sure you want to end the Tour? You can always start the tour from the{" "}
              <strong>Resources</strong> page in your account menu.
            </>
          }
          modifierClass="modal--md modal--stack-tours"
          overlayModifierClass="modal--stack-tours"
          handleCloseModal={() => setShowCancelTourModal(false)}
        >
          <div className="modal__actions">
            <Button
              modifierClass="btn--fluid btn--primary"
              onClick={() => setShowCancelTourModal(false)}
            >
              Cancel
            </Button>

            <Button
              modifierClass="txt--capitalize btn--fluid btn--secondary"
              onClick={() => handleTourEnd()}
            >
              Yes, End Tour
            </Button>
          </div>
        </Modal>
      ) : null}
    </TourContext.Provider>
  );
};

const useTour = () => useContext(TourContext);

export { TourContext, TourContextWrapper, useTour };
