import { Link } from "react-router-dom";

// Components
import Accordion from "../Accordion/Accordion";
import ApplicationSBCA from "./ApplicationSBCA";
import ApplicationSBCAResult from "./ApplicationSBCAResult";
import Button from "../Button/Button";
import Loader from "../Loader/Loader";

// Hooks & Utilities
import { useAuth } from "../../providers/auth-context";
import { useApplicationRegenerateSbcaAiSummary } from "../../api/Applications/Applications";
import { format } from "date-fns";
import { handleDateAsTimestamp } from "../../utilities/dates/handleDateAsTimestamp";
import handleFullnameCombination from "../../utilities/strings/handleFullnameCombination";
import useErrorReporting from "../../hooks/useErrorReporting";

// Interfaces
import { ApplicationDetailsFields } from "../../api/Applications/interfaces";
import { UserRoleNames } from "../../interfaces/global";
import MarkdownWrapper from "../Markdown/Markdown";

interface ApplicationSBCADetailsProps {
  application: ApplicationDetailsFields;
  isOpenByDefault: boolean;
}

function ApplicationSBCADetails({
  application,
  isOpenByDefault = false,
}: ApplicationSBCADetailsProps) {
  const { user } = useAuth();
  const errorReporting = useErrorReporting();
  const regenerateSbcaSummary = useApplicationRegenerateSbcaAiSummary();
  const { ai_sbca_summary } = application;

  const handleRegenerateSbcaummary = async () => {
    try {
      await regenerateSbcaSummary.mutateAsync({
        applicationID: application.id,
        sbcaIdentifier: application.applicant.sbca_report_identifier,
      });
    } catch (error) {
      errorReporting("Failed requesting SBCA AI Summary regeneration", error, {
        applicationID: application.id,
        sbcaIdentifier: application.applicant.sbca_report_identifier,
      });
    }
  };

  // Do not render the "SBCA Results" section if SBCA is disabled
  if (user.active_company.disable_sbca) return null;

  return (
    <Accordion
      id="accordion-sbca-results"
      title="SBCA Results"
      modifierClass="accordion--rounded"
      isOpenByDefault={isOpenByDefault}
    >
      <div className="d-flex flex-column flex-sm-row justify-content-between align-items-center">
        <div className="d-flex flex-column flex-sm-row align-items-center w--100">
          <div
            className={`d-flex flex-column flex-sm-row justify-content-between align-items-center flex-shrink-0 ${
              // This is applied so we can separate the text elements to be on opposite sides
              !application.applicant.sbca?.score ? "w--100" : ""
            }`}
          >
            {application.applicant.sbca?.score ? (
              <div>
                <h6 className="fw--semibold">Total Score:</h6>
                <p className="txt--xxs m--0">
                  <i>Target Range 0-10</i>
                </p>
              </div>
            ) : (
              <p className="accordion__empty-message">No SBCA data to display.</p>
            )}

            <ApplicationSBCA
              id={application.id}
              name={handleFullnameCombination(application.applicant)}
              email={application.applicant.email}
              sbca={
                application.applicant.sbca?.score != null ? +application.applicant.sbca.score : null
              }
              sbca_report_identifier={
                application.applicant.sbca_report_identifier != null
                  ? application.applicant.sbca_report_identifier
                  : null
              }
              sbca_request_date={application.applicant.sbca_request_date}
              modifierClass="applications__sbca--horizontal mx--20"
            />
          </div>

          {application.applicant.sbca_report_identifier != null && (
            <div className="d-flex justify-content-center justify-content-sm-end w--100 mt--sm--10">
              <Link
                to={`/assessment/results/${application.applicant.sbca_report_identifier}/`}
                className="d-inline-block btn btn--fluid btn--transparent btn--border btn--border--primary btn--pill"
                target="_blank"
              >
                View SBCA
              </Link>
            </div>
          )}
        </div>
      </div>

      {application.applicant.sbca != null && (
        <div className="d-flex flex-column flex-md-row align-items-start mt--25">
          <ApplicationSBCAResult type="caregiver" details={application.applicant.sbca} />
          <ApplicationSBCAResult type="thinker" details={application.applicant.sbca} />
          <ApplicationSBCAResult type="processor" details={application.applicant.sbca} />
        </div>
      )}

      {/* SBCA AI Summary */}
      {application.applicant.sbca != null && user.role === UserRoleNames.SUPER_ADMIN ? (
        <>
          <h3 className="fw--semibold mt--10 mb--15">SBCA Summary</h3>
          <hr />

          {ai_sbca_summary.is_sbca_regenerated ? (
            <div className="d-flex align-items-center mb--20">
              <Loader size="sm" modifierWrapper="mr--20" />
              <p className="accordion__empty-message">A SBCA summary is being generated...</p>
            </div>
          ) : (
            <>
              {ai_sbca_summary.sbca_summary ? (
                <MarkdownWrapper markdown={ai_sbca_summary.sbca_summary} />
              ) : (
                <p className="white-space--pre-line mb--10">No SBCA summary to display.</p>
              )}
            </>
          )}

          {/* Only show the button when summary is not being (re)generated */}
          {!ai_sbca_summary.is_sbca_regenerated ? (
            <>
              <hr />

              <Button
                modifierClass="btn--fluid btn--fluid--md btn--primary fw--semibold mb--10"
                onClick={handleRegenerateSbcaummary}
                isDisabled={ai_sbca_summary.is_sbca_regenerated || regenerateSbcaSummary.isLoading}
                isLoading={regenerateSbcaSummary.isLoading}
              >
                {ai_sbca_summary.last_regenerated_sbca_date
                  ? "Regenerate Summary"
                  : "Generate Summary"}
              </Button>

              <p className="mb--0">Last Generated Date: </p>
              <p className="mb--0">
                {ai_sbca_summary.is_sbca_regenerated
                  ? "Generating..."
                  : ai_sbca_summary.last_regenerated_sbca_date
                  ? format(
                      new Date(handleDateAsTimestamp(ai_sbca_summary.last_regenerated_sbca_date)),
                      "MM/dd/yyyy hh:mm a",
                    )
                  : "Never"}
              </p>
            </>
          ) : null}
        </>
      ) : null}
    </Accordion>
  );
}

export default ApplicationSBCADetails;
