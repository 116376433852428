import React, { createContext, PropsWithChildren, useContext, useEffect, useState } from "react";

// Interfaces
import { AuthenticationContextInitialStateProps } from "./interfaces";
import { UsersResponseFields } from "../api/Users/interfaces";

// Hooks & Utilities
import { useUserProfileData } from "../api/User/User";
import { useNavigate } from "react-router-dom";
import { handleAuthenticationTokensInLocalStorage } from "../api/tokenRefresher";
import { LocalStorageActions } from "../utilities/handleLocalStorage";

// Constants
import { USER_PROFILE_DEFAULT_DATASET } from "../constants/users";

// Initial state for the Authentication Context
const AuthContext = createContext<AuthenticationContextInitialStateProps>({
  isAuthenticated: false,
  user: USER_PROFILE_DEFAULT_DATASET,
  handleAuthenticationTokens: () => undefined,
  handleClearAuthenticationContext: () => undefined,
});

const AuthenticationContext: React.FC<PropsWithChildren> = ({ children }) => {
  const navigate = useNavigate();

  /*===============================
    AUTHENTICATE THE USER

    Handle user authentication upon page load
    and allow or prevent access to the application
  ================================*/
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(() => {
    return Boolean(LocalStorageActions.getItem("accessToken"));
  });

  // Update the access token value that grants control
  // to the authenticated part of the application,
  // and save the newly issued tokens in storage
  const handleAuthenticationTokens = (
    accessToken: string,
    refreshToken: string,
    expiresIn: number,
    autologin: boolean = false,
  ) => {
    setIsAuthenticated(!!accessToken);

    handleAuthenticationTokensInLocalStorage(accessToken, refreshToken, expiresIn, autologin);
  };

  /*===============================
    AUTHENTICATED USER DETAILS

    Fetch the currently logged in user details
    from the API and use that data to populate the
    user's authentication context provider
  ================================*/
  const [userDetails, setUserDetails] = useState<UsersResponseFields>(USER_PROFILE_DEFAULT_DATASET);
  const { data: userData, isLoading: userDataIsLoading } = useUserProfileData(isAuthenticated);

  // Obtain the user's profile data from the API,
  // and once we get the response back, update the state that is
  // then made available to all components trough the context provider
  useEffect(() => {
    if (!userData || userDataIsLoading) return;

    // If there's no 'active company' redirect to 'Profile Settings'
    if (userData && userData.active_company == null) {
      setUserDetails({ ...userData, active_company: USER_PROFILE_DEFAULT_DATASET.active_company });
      navigate("/account/profile/");
    } else {
      setUserDetails(userData);
    }
  }, [userData]);

  /*===============================
    CLEAR AUTHENTICATION CONTEXT

    Clear (reset) the authentication context
    values to their default set when a user logs out
  ================================*/
  const handleClearAuthenticationContext = () => {
    setUserDetails(USER_PROFILE_DEFAULT_DATASET);
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        user: userDetails,
        handleAuthenticationTokens,
        handleClearAuthenticationContext,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

// Hook to handle the usage of the authentication context
const useAuth = () => useContext(AuthContext);

export { AuthenticationContext, AuthContext, useAuth };
