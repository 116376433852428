// Pages
import OnlineApplicationAssessment from "../pages/OnlineApplication/Assessment/OnlineApplicationAssessment";
import OnlineApplicationAssessmentThankYou from "../pages/OnlineApplication/Assessment/OnlineApplicationAssessmentThankYou";
import OnlineApplication from "../pages/OnlineApplication/OnlineApplication";
import OnlnieApplicationPolicy from "../pages/OnlineApplication/OnlineApplicationPolicy";
import OnlineApplicationThankYou from "../pages/OnlineApplication/OnlineApplicationThankYou";
import OnlineApplicationUploadPhoto from "../pages/OnlineApplication/OnlineApplicationUploadPhoto";
import OnlineApplicationUploadResume from "../pages/OnlineApplication/OnlineApplicationUploadResume";
import OnlineApplicationUploadThankYou from "../pages/OnlineApplication/OnlineApplicationUploadThankYou";

// Interfaces
import { ApplicationRouteProps } from "./interfaces";

export const ROUTES_ONLINE_APPLICATION: ApplicationRouteProps[] = [
  {
    path: "/online-application/initialize-oa/:slug",
    element: <OnlineApplication />,
    permissions: ["*"],
    type: "public",
  },
  {
    path: "/online-application/initialize-oa/thank-you/:identifier",
    element: <OnlineApplicationThankYou />,
    permissions: ["*"],
    type: "public",
  },
  {
    path: "/online-application/assessment/:identifier",
    element: <OnlineApplicationAssessment />,
    permissions: ["*"],
    type: "public",
  },
  {
    path: "/online-application/assessment/:locale/thank-you",
    element: <OnlineApplicationAssessmentThankYou />,
    permissions: ["*"],
    type: "public",
  },
  {
    path: "/online-application/assessment/thank-you",
    element: <OnlineApplicationAssessmentThankYou />,
    permissions: ["*"],
    type: "public",
  },
  {
    path: "/online-application/attach-files/:id",
    element: <OnlineApplicationUploadResume />,
    permissions: ["*"],
    type: "public",
  },
  {
    path: "/online-application/attach-photo/:id",
    element: <OnlineApplicationUploadPhoto />,
    permissions: ["*"],
    type: "public",
  },
  {
    path: "/online-application/policy",
    element: <OnlnieApplicationPolicy />,
    permissions: ["*"],
    type: "public",
  },
  {
    path: "/online-application/upload/:type/thank-you",
    element: <OnlineApplicationUploadThankYou />,
    permissions: ["*"],
    type: "public",
  },
];
