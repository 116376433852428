// Components
import Accordion from "../../components/Accordion/Accordion";
import Button from "../../components/Button/Button";
import Loader from "../../components/Loader/Loader";

// Utilities & Hooks
import { format } from "date-fns";
import { handleDateAsTimestamp } from "../../utilities/dates/handleDateAsTimestamp";
import { useAuth } from "../../providers/auth-context";
import { useApplicationRegenerateResumeAiSummary } from "../../api/Applications/Applications";
import useErrorReporting from "../../hooks/useErrorReporting";

// Interfaces
import { ApplicationDetailsFields } from "../../api/Applications/interfaces";
import { UserRoleNames } from "../../interfaces/global";
import MarkdownWrapper from "../../components/Markdown/Markdown";

interface ApplicantSummaryProps {
  application: ApplicationDetailsFields;
  isOpenByDefault: boolean;
}

function ApplicantSummary({ application, isOpenByDefault }: ApplicantSummaryProps) {
  const { user } = useAuth();
  const regenerateResumeSummary = useApplicationRegenerateResumeAiSummary();
  const errorReporting = useErrorReporting();
  const { ai_resume_summary } = application;

  const handleRegenerateResumeSummary = async () => {
    try {
      await regenerateResumeSummary.mutateAsync({
        applicationID: application.id,
        encrypted_application_id: application.encrypted_application_id,
      });
    } catch (error) {
      errorReporting("Failed requesting Resume AI Summary regeneration", error, {
        applicationID: application.id,
        encrypted_application_id: application.encrypted_application_id,
      });
    }
  };

  // If there's no "Resume" display fallback
  if (
    !ai_resume_summary ||
    (!ai_resume_summary.resume_summary && !ai_resume_summary.is_resume_regenerated)
  ) {
    return (
      <Accordion
        id="accordion-applicant-summary"
        title="Applicant Summary"
        modifierClass="accordion--rounded"
        isOpenByDefault={isOpenByDefault}
      >
        <p className="accordion__empty-message">No resume summary to display</p>

        {application.resume && user.role === UserRoleNames.SUPER_ADMIN ? (
          <>
            <hr className="mb--20" style={{ backgroundColor: "#eee" }} />

            <Button
              modifierClass="btn--fluid btn--fluid--md btn--primary fw--semibold mb--10"
              onClick={handleRegenerateResumeSummary}
              isDisabled={regenerateResumeSummary.isLoading}
              isLoading={regenerateResumeSummary.isLoading}
            >
              Generate Summary
            </Button>
          </>
        ) : null}
      </Accordion>
    );
  }

  if (ai_resume_summary.is_resume_regenerated) {
    return (
      <Accordion
        id="accordion-applicant-summary"
        title="Applicant Summary"
        modifierClass="accordion--rounded"
        isOpenByDefault={isOpenByDefault}
      >
        <div className="d-flex align-items-center">
          <Loader size="sm" modifierWrapper="mr--20" />
          <p className="accordion__empty-message">A resume summary is being generated...</p>
        </div>
      </Accordion>
    );
  }

  return (
    <Accordion
      id="accordion-applicant-summary"
      title="Applicant Summary"
      modifierClass="accordion--rounded"
      isOpenByDefault={isOpenByDefault}
    >
      <MarkdownWrapper markdown={ai_resume_summary.resume_summary} />

      {user.role === UserRoleNames.SUPER_ADMIN ? (
        <>
          <hr className="mb--20" style={{ backgroundColor: "#eee" }} />

          <Button
            modifierClass="btn--fluid btn--fluid--md btn--primary fw--semibold mb--10"
            onClick={handleRegenerateResumeSummary}
            isDisabled={
              ai_resume_summary.is_resume_regenerated || regenerateResumeSummary.isLoading
            }
            isLoading={regenerateResumeSummary.isLoading}
          >
            {ai_resume_summary.last_regenerated_resume_date
              ? "Regenerate Summary"
              : "Generate Summary"}
          </Button>

          <p className="mb--0">Last Generated Date: </p>
          <p className="mb--0">
            {ai_resume_summary.is_resume_regenerated
              ? "Generating..."
              : ai_resume_summary.last_regenerated_resume_date
              ? format(
                  new Date(handleDateAsTimestamp(ai_resume_summary.last_regenerated_resume_date)),
                  "MM/dd/yyyy hh:mm a",
                )
              : "Never"}
          </p>
        </>
      ) : null}
    </Accordion>
  );
}

export default ApplicantSummary;
