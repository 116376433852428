import { UsersResponseFields } from "../api/Users/interfaces";

export const USER_PROFILE_DEFAULT_DATASET: UsersResponseFields = {
  id: 0,
  first_name: "",
  last_name: "",
  title: "",
  email: "",
  account_key: null,
  role: "",
  companies: [],
  permissions: [],
  active_company: {
    id: 0,
    name: "",
    slug: "",
    form_identifier: "",
    disable_sbca: false,
    is_career_page_enabled: false,
  },
  last_login: null,
  status: "active",
  unwatched_product_tours: [],
};
