import { BannerProps } from "./interface";

const Banner = ({ text, icon = null, modifierClass = "" }: BannerProps) => {
  return (
    <div className={`banner ${modifierClass}`}>
      {icon && <div className="banner__icon">{icon}</div>}

      <p className="banner__text">{text}</p>
    </div>
  );
};

export default Banner;
