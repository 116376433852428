// Hooks & Utilities
import { Link, useParams } from "react-router-dom";
import { format } from "date-fns";
import { useEffect, useRef, useState } from "react";
import {
  useApplicationHide,
  useApplicationUnhide,
  useApplicationsGetSpecific,
} from "../../api/Applications/Applications";
import { useAuth } from "../../providers/auth-context";
import { handleApplicantCityAndState } from "../../utilities/strings/handleApplicantCityAndState";
import { useBucketsGetAll } from "../../api/Buckets/Buckets";
import useErrorReporting from "../../hooks/useErrorReporting";
import handleFullnameCombination from "../../utilities/strings/handleFullnameCombination";
import fileDownloadHandler from "../../api/fileDownloadHandler";
import handlePhoneStringSanitization from "../../utilities/strings/handlePhoneStringSanitization";
import { useBackNavigation } from "../../hooks/useBackNavigation";

// Assets
import { FaChevronLeft as BackIcon, FaMapMarkerAlt as LocationIcon } from "react-icons/fa";

import {
  MdPhoneInTalk as PhoneIcon,
  MdOutlineAlternateEmail as ApplicationMailIcon,
} from "react-icons/md";

import EmailIcon from "../../assets/images/icons/applications-email-application-icon.svg?react";
import PrintIcon from "../../assets/images/icons/applications-print-application-icon.svg?react";
import RefreshIcon from "../../assets/images/icons/applications-refresh-page-icon.svg?react";

// Components
import ApplicationBucketMovement from "../../components/Applications/Buckets/ApplicationBucketMovement";
import Tooltip from "../../components/Tooltip/Tooltip";
import Card from "../../components/Card/Card";
import ApplicationRating from "../../components/Applications/ApplicationRating";
import ApplicationPhoto from "../../components/Applications/ApplicationPhoto";
import ApplicationArchive from "../../components/Applications/Buckets/ApplicationArchive";
import ApplicationFavorite from "../../components/Applications/Buckets/ApplicationFavorite";
import ApplicationSendText from "../../components/Applications/Socials/ApplicationSendText";
import ApplicationResume from "../../components/Applications/Socials/ApplicationResume";
import ApplicationLinkedIn from "../../components/Applications/Socials/ApplicationLinkedIn";
import ApplicationScheduleMeeting from "../../components/Applications/Socials/ApplicationScheduleMeeting";
import Loader from "../../components/Loader/Loader";
import Button from "../../components/Button/Button";
import ScheduleMeetingMenu from "../../components/Applications/ScheduleMeeting/ScheduleMeetingMenu";
import AppointmentsListItem from "../../components/Appointments/AppointmentsListItem";
import ApplicationComment from "../../components/Applications/ApplicationComment";
import ApplicationCommentMenu from "../../components/Applications/ApplicationCommentMenu";
import ApplicationDetailsSkeleton from "./Skeleton/ApplicationDetailsSkeleton";
import ApplicationEmailModal from "../../components/Applications/modals/ApplicationEmailModal";
import ApplicationModalResumeRequest from "../../components/Applications/modals/ApplicationModalResumeRequest";
import PermissionCheckComponentWrapper from "../../components/Wrappers/PermissionCheckComponentWrapper";
import ApplicationVerifiedFirst from "../../components/Applications/ApplicationVerifiedFirst";
import ApplicationExtraInformation from "../../features/Applications/ApplicationExtraInformation";
import ApplicationSourceAdmin from "../../components/Applications/ApplicationSourceAdmin";
import ModalApplicationNotInCompany from "../../components/Modal/ModalApplicationNotInCompany";
import ApplicationDateSection from "../../components/Applications/ApplicationDateSection";
import ApplicationHistoryWrapper from "../../features/Applications/ApplicationHistory";
import ApplicationSendEmail from "../../components/Applications/Socials/ApplicationSendEmail";
import ApplicationsDetailsAdminActions from "../../components/Applications/ApplicationsDetailsAdminActions";
import ApplicationSBCADetails from "../../components/Applications/ApplicationSBCADetails";
import ApplicationGeneratedInterviewQuestions from "../../features/Applications/ApplicationGeneratedInterviewQuestions";
import ApplicantSummary from "../../features/Applications/ApplicantSummary";

// Interfaces
import { AppointmentResponseFields } from "../../api/Appointments/interfaces";
import { ApplicationCommentsFields } from "../../api/Applications/interfaces";
import { AnimatePresence } from "framer-motion";
import { UserRoleNames } from "../../interfaces/global";

const ApplicationsDetails = () => {
  const { user } = useAuth();
  const { applicationID } = useParams();
  const errorReporting = useErrorReporting();

  /*=========================
    GET ALL BUCKETS

    Fetch the list of all existing buckets on page
    load to allow working with them providing option for optimistic updates
  ==========================*/
  const { isLoading: bucketsLoading } = useBucketsGetAll();

  /*=====================
    HEADER ACTION ICONS
  ======================*/
  const handleRefreshPage = () => window.location.reload();

  /*=========================
    MODAL FOR COMPANY CHANGE
  ==========================*/
  const [showCompanyChangeModal, setShowCompanyChangeModal] = useState<boolean>(false);

  /*=====================
    APPLICATION DETAILS
  ======================*/
  const {
    data: application,
    isLoading: applicationIsLoading,
    isRefetching: applicationIsRefetching,
    isError,
    refetch,
  } = useApplicationsGetSpecific(applicationID);
  const [showSkeletonLoader, setShowSkeletonLoader] = useState<boolean>(true);

  useEffect(() => {
    // If there's no data received yet remain in loading state
    if (!application || applicationIsLoading || bucketsLoading) return;

    // If data is received but user is not in this company, still remain in the loading state
    if (application.company_change) {
      setShowSkeletonLoader(true);
      setShowCompanyChangeModal(true);
      return;
    }

    // Otherwise hide the skeleton loading placeholder
    setShowSkeletonLoader(false);
  }, [application, bucketsLoading]);

  // In case there's an error returned from the API response, hide the skeleton loading placeholder
  useEffect(() => {
    if (isError) setShowSkeletonLoader(false);
  }, [isError]);

  /*=====================
    EMAIL APPLICATION
  ======================*/
  const [emailApplicationModal, setEmailApplicationModal] = useState<boolean>(false);

  /*========================
    DOWNLOAD RESUME
  ========================*/
  const handleDownloadResume = async (resumeURL: string | null) => {
    if (!resumeURL) return;

    try {
      await fileDownloadHandler(
        `company/${user.active_company.slug}/applications/${applicationID}/resume/download`,
      );
    } catch (error) {
      errorReporting("Failed downloading application resume", error, {
        application_id: applicationID,
        company_slug: user.active_company.slug,
      });
    }
  };

  /*========================
    SCHEDULE MEETING

    If there's an existing "Video Meeting" appointment
    scheduled for a future date, then redirect the user to the
    video conference page when clicked on the "Start Meeting" icon.

    If there's no existing "Video Meeting" appointment
    scheduled for a future date, then toggle open/close the "Schedule Appointments" menu.
  ========================*/
  const [showScheduleAppointmentsMenu, setShowScheduleAppointmentsMenu] = useState<boolean>(false);

  // Scroll the schedule appointments component into view on opening (for more mobile-friendly experience)
  const appointmentsComponentRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (!showScheduleAppointmentsMenu || !appointmentsComponentRef.current) return;

    appointmentsComponentRef.current.scrollIntoView();
  }, [showScheduleAppointmentsMenu]);

  const handleScheduleMeeting = () => {
    if (application?.schedule_meeting) {
      window.open(`/vidconf/join/${application.schedule_meeting}`);
    } else {
      setShowScheduleAppointmentsMenu(!showScheduleAppointmentsMenu);
    }
  };

  /*=====================
    COMMENTS
  ======================*/
  const [showCommentsEditMenu, setShowCommentsEditMenu] = useState<boolean>(false);
  const [showCommentsCreateMenu, setShowCommentsCreateMenu] = useState<boolean>(false);
  const [commentDetails, setCommentDetails] = useState<ApplicationCommentsFields | null>(null);

  const handleCommentEdit = (commentID: number) => {
    // Find the matching comment and select its details
    const matchingComment = application?.comments.find(comment => comment.id === commentID);

    // Exit function if no match is found
    if (!matchingComment) return;

    // Render the menu in the UI and populate the form details
    setShowCommentsEditMenu(true);
    setCommentDetails(matchingComment);
  };

  /*=====================
    REQUEST RESUME MODAL

    If the application does not have a resume
    associated with it, clicking on "Resume" icon
    will open the modal for requesting the resume
  ======================*/
  const [showApplicationRequestResumeModal, setShowApplicationRequestResumeModal] =
    useState<boolean>(false);

  // Trigger "Resume" download (if present), or request one
  const handleApplicationRequestResumeModal = (resume: string | null) => {
    if (resume) {
      handleDownloadResume(resume);
    } else {
      setShowApplicationRequestResumeModal(true);
    }
  };

  /*=========================
    GOOGLE MAPS 
    APPLICANT ADDRESS LINK
  ===========================*/
  const [applicantMapLink, setApplicantMapLink] = useState<string>("");

  useEffect(() => {
    if (!application || !Object.entries(application).length) return;

    // If a company change field exists in the response, exit the function
    // as we won't be rendering anything in the UI and won't have the necessary fields
    if (application.company_change) return;

    // Construct the entire address of the applicant
    // to be used as a query parameter for Google Maps
    const address: string = application.applicant.address || "";
    const addressDetails: string = handleApplicantCityAndState(
      application.applicant.city,
      application.applicant.state,
      application.applicant.zip_code,
    );

    const addressFinalString: string = `${address ? `${address} ` : ""}${addressDetails}`;
    setApplicantMapLink(addressFinalString);
  }, [application]);

  const handleNavigateBack = useBackNavigation();

  /*=========================
    HIDE & UNHIDE APPLICATION
  ===========================*/
  const applicationHide = useApplicationHide();
  const applicationUnhide = useApplicationUnhide();

  const handleApplicationHideAndUnhide = async () => {
    if (!application || !applicationID) return;

    // If hide or unhide action is still ongoing, prevent another mutation
    if (applicationHide.isLoading || applicationUnhide.isLoading) return;

    try {
      if (application.is_hidden) {
        await applicationUnhide.mutateAsync(applicationID);
      } else {
        await applicationHide.mutateAsync(applicationID);
      }
    } catch (error) {
      errorReporting(
        `Failed ${
          application.is_hidden ? "unhiding application" : "marking application as hidden"
        }`,
        error,
        {
          application_id: applicationID,
          company_slug: user.active_company.slug,
        },
      );
    }
  };

  /*=============================
    REFETCH THE AI SUMMARY DATA
    (both for "Resume" and "SBCA")
  ==============================*/
  useEffect(() => {
    if (!application || applicationIsLoading || application.company_change) return;

    // Only available to Super Admin users
    if (user.role !== UserRoleNames.SUPER_ADMIN) return;

    // Refetch the data every 15 seconds until the Resume summary was is regenerated
    if (
      application.ai_resume_summary.is_resume_regenerated ||
      application.ai_sbca_summary.is_sbca_regenerated
    ) {
      const interval = setInterval(() => refetch(), 15000);
      return () => clearInterval(interval);
    }
  }, [application, applicationIsLoading, user]);

  /*=============================
    CONTROL ACCORDION STATE
  ==============================*/
  const [defaultOpenAccordion, setDefaultOpenAccordion] = useState<string>("");

  useEffect(() => {
    if (!application || application.company_change) return;

    const { ai_resume_summary, applicant } = application;

    // Only try to mark the "Applicant Summary" accordion to be open by default
    // if the user is a Super Admin and there is a generated AI Resume summary
    if (user.role === UserRoleNames.SUPER_ADMIN && ai_resume_summary.resume_summary) {
      setDefaultOpenAccordion("applicant-summary");
      return;
    }

    if (applicant.sbca_report_identifier) {
      setDefaultOpenAccordion("sbca-results");
      return;
    }

    setDefaultOpenAccordion("employment-history");
  }, [application]);

  // Render skeleton loader
  if (showSkeletonLoader && !isError) {
    return (
      <div className="container py--25 applications-details">
        <ApplicationDetailsSkeleton />
        <AnimatePresence>
          {application && application.company_change && showCompanyChangeModal ? (
            <ModalApplicationNotInCompany
              id={application.company_change.id}
              name={application.company_change.name}
            />
          ) : null}
        </AnimatePresence>
      </div>
    );
  }

  // If request is not loading and there is no data render 'no-data' message
  if (!application && !applicationIsLoading) {
    return (
      <div className="container py--25 applications-details">
        <div className="d-flex flex-column align-items-center justify-content-center wrapper--height">
          <h2 className="txt--center fw--semibold mb--30">No Application data available.</h2>

          <div>
            <Button
              modifierClass="btn--fluid btn--primary--light"
              onClick={() => handleNavigateBack("/applications/")}
            >
              Go Back
            </Button>

            <Button
              modifierClass="btn--fluid btn--primary ml--10"
              onClick={() => window.location.reload()}
            >
              Reload Page
            </Button>
          </div>
        </div>
      </div>
    );
  }

  // If the above conditions do not pass, the application is ready to be
  // rendered on the details page
  if (application) {
    return (
      <div className="container py--25 applications-details">
        <div className="row mb--20 mb--md--10">
          <div className="col-12 col-lg-8 mb--20">
            <div className="d-flex flex-column flex-md-row justify-content-between align-items-start">
              <span
                className="d-flex align-items-center fw--medium txt--lg txt--link mb--md--15"
                onClick={() => handleNavigateBack("/applications/")}
              >
                <BackIcon className="mr--5" />
                <span>Go Back</span>
              </span>

              <div className="d-flex flex-column flex-md-row justify-content-between align-items-md-center w--sm-100">
                {user.role === UserRoleNames.SUPER_ADMIN ||
                user.role === UserRoleNames.ACCOUNT_MANAGER ? (
                  <Button
                    modifierClass="btn--sm btn--danger mr--10 mb--md--15"
                    onClick={handleApplicationHideAndUnhide}
                    isDisabled={applicationHide.isLoading || applicationUnhide.isLoading}
                  >
                    {application.is_hidden ? "Unhide" : "Hide"} Application
                  </Button>
                ) : null}

                <PermissionCheckComponentWrapper permissions={["buckets_view"]}>
                  <ApplicationBucketMovement
                    key={application.bucket[0] || "change-application-bucket"}
                    title={application.bucket?.[0] ?? "Move Application"}
                    preselectedBucket={application.bucket?.[0] ?? ""}
                    id={parseInt(applicationID!)}
                    handleApplicationsResetSelection={() => undefined}
                    name={handleFullnameCombination(application.applicant)}
                    isDisabled={application.is_hidden}
                  />
                </PermissionCheckComponentWrapper>
              </div>
            </div>
          </div>

          <div className="col-12 col-lg-4 d-flex justify-content-end pr--30">
            <div className="applications-details__header__icons">
              <Tooltip text="Email Application" size="md">
                <EmailIcon
                  className="applications-details__header__icon"
                  onClick={() => setEmailApplicationModal(true)}
                  data-testid="button:share-email"
                />
              </Tooltip>
              <Tooltip text="Print Application" size="md" wrapperModifierClass="ml--10">
                <Link
                  to={`/applications/${applicationID}/print/`}
                  target="_blank"
                  data-testid="button:print"
                >
                  <PrintIcon className="applications-details__header__icon" />
                </Link>
              </Tooltip>

              <Tooltip text="Refresh Page" size="md" wrapperModifierClass="ml--10">
                <RefreshIcon
                  className="applications-details__header__icon"
                  onClick={handleRefreshPage}
                  data-testid="button:refresh"
                />
              </Tooltip>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-lg-8">
            {/* APPLICANT DETAILS */}
            <Card modifierClass={`card--rounded ${application.is_hidden ? "card--hidden" : ""}`}>
              <div className="row">
                <div className="col-12 col-md-3">
                  <div className="d-flex flex-column justify-content-center align-items-center mb--md--15">
                    <ApplicationRating
                      comments_avg_rating={application.comments_avg_rating ?? null}
                    />
                    <ApplicationPhoto
                      name={handleFullnameCombination(application.applicant)}
                      photo={application.applicant?.photo ?? ""}
                      sbca={application.applicant.sbca?.score ?? null}
                    />

                    <ApplicationDateSection
                      active_bucket={null}
                      applicant_hired_date={application.hired_date}
                      applicant_name={`${application.applicant.first_name} ${application.applicant.last_name}`}
                      application_id={application.id}
                      registration_date={application.registration_date}
                      applicant_bucket={application.bucket[0]}
                    />
                  </div>
                </div>

                <div className="col-12 col-md-9">
                  <div className="d-flex flex-column flex-sm-row justify-content-between align-items-center align-items-sm-start mb--20">
                    <div className="applications__details">
                      <h4
                        className="applications__details__name break-word"
                        title={handleFullnameCombination(application.applicant)}
                      >
                        {handleFullnameCombination(application.applicant)}
                      </h4>

                      <p
                        className="applications__details__position break-word"
                        title={application.position}
                      >
                        {application.position || "N/A"}
                      </p>
                    </div>

                    <PermissionCheckComponentWrapper permissions={["buckets_view"]}>
                      <div className="d-flex align-items-center">
                        <span className="mr--15">
                          <ApplicationArchive
                            id={application.id ?? 0}
                            currentBuckets={application.bucket}
                            name={handleFullnameCombination(application.applicant)}
                          />
                        </span>

                        <ApplicationFavorite
                          id={application.id ?? 0}
                          currentBuckets={application.bucket}
                          name={handleFullnameCombination(application.applicant)}
                        />
                      </div>
                    </PermissionCheckComponentWrapper>
                  </div>

                  <div className="row no-gutters mb--35 mb--sm--15">
                    <div className="col-12 col-sm-6 mb--sm--15">
                      <div className="applications__contact__location">
                        <LocationIcon className="mr--5" />

                        {applicantMapLink ? (
                          <a
                            href={`https://maps.google.com/maps?q=${applicantMapLink}`}
                            target="_blank"
                            rel="noopener"
                            className="pr--30"
                            title={applicantMapLink}
                          >
                            <span className="d-block mb--10">
                              {application.applicant.address || "N/A"}
                            </span>
                            <span className="d-block m--0">
                              {handleApplicantCityAndState(
                                application.applicant.city,
                                application.applicant.state,
                                application.applicant.zip_code,
                              )}
                            </span>
                          </a>
                        ) : (
                          <p className="mb--0 pr--30">
                            <span className="d-block mb--10">
                              {application.applicant.address || "N/A"}
                            </span>
                            <span className="d-block m--0">
                              {handleApplicantCityAndState(
                                application.applicant.city,
                                application.applicant.state,
                                application.applicant.zip_code,
                              ) || "N/A"}
                            </span>
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="col-12 col-sm-6 mb--sm--15">
                      <div className="d-flex flex-column">
                        <div
                          className="applications__contact__phone"
                          title={
                            handlePhoneStringSanitization(application.applicant.phone) || "N/A"
                          }
                        >
                          <PhoneIcon className="mr--5" />

                          {application.applicant?.phone ? (
                            <a
                              href={`tel:${handlePhoneStringSanitization(
                                application.applicant.phone,
                              )}`}
                              className="break-word"
                            >
                              {handlePhoneStringSanitization(application.applicant.phone)}
                            </a>
                          ) : (
                            <p className="m--0">N/A</p>
                          )}
                        </div>

                        {application.applicant.email ? (
                          <a href={`mailto: ${application.applicant.email}`} className="break-word">
                            <ApplicationMailIcon className="applications__contact__email" />{" "}
                            {application.applicant.email}
                          </a>
                        ) : (
                          <p className="m--0">N/A</p>
                        )}
                      </div>
                    </div>
                  </div>

                  {/* APPLICATION SOURCE */}
                  <ApplicationSourceAdmin source={application.source} modifierClass="mb--20" />

                  <hr className="mb--20" />

                  <div className="d-flex align-items-center justify-content-center justify-content-md-start flex-wrap">
                    <ApplicationSendText
                      application_id={application.id}
                      applicant_details={{
                        id: application.applicant.id,
                        text_message_consent: application.text_message_consent,
                        name: handleFullnameCombination(application.applicant),
                        photo: application.applicant.photo ?? null,
                      }}
                    />

                    <ApplicationResume
                      resume={application.resume}
                      tooltipText={
                        application.resume
                          ? "Download Resume"
                          : application.resume_request_date
                          ? `Resume Requested On ${format(
                              new Date(application.resume_request_date),
                              "MM/dd/yyyy",
                            )}. Click to Request Again`
                          : "Request Resume"
                      }
                      handleResume={handleApplicationRequestResumeModal}
                    >
                      <AnimatePresence>
                        {showApplicationRequestResumeModal && (
                          <ApplicationModalResumeRequest
                            id={application.id}
                            name={handleFullnameCombination(application.applicant)}
                            email={application.applicant.email}
                            handleCloseModal={() => setShowApplicationRequestResumeModal(false)}
                          />
                        )}
                      </AnimatePresence>
                    </ApplicationResume>

                    <ApplicationLinkedIn linkedin={application.linkedin ?? ""} />

                    <ApplicationSendEmail
                      details={{
                        applicantName: `${application.applicant.first_name} ${application.applicant.last_name}`,
                        applicantEmail: application.applicant.email,
                        applicationDate: application.registration_date,
                        applicationPosition: application.position,
                        applicantLocation: `${application.applicant.city}, ${application.applicant.state}`,
                      }}
                    />

                    <ApplicationScheduleMeeting
                      meeting_type="video"
                      tooltipText={
                        application.schedule_meeting ? "Start Video Interview" : "Video Interview"
                      }
                      handleScheduleMeeting={handleScheduleMeeting}
                    />

                    <ApplicationVerifiedFirst details={application} />
                  </div>
                </div>
              </div>
            </Card>

            {/* APPLICANT SUMMARY */}
            {user.role === UserRoleNames.SUPER_ADMIN ? (
              <ApplicantSummary
                application={application}
                isOpenByDefault={defaultOpenAccordion === "applicant-summary"}
              />
            ) : null}

            {/* SBCA RESULTS DETAILS */}
            <ApplicationSBCADetails
              application={application}
              isOpenByDefault={defaultOpenAccordion === "sbca-results"}
            />

            {/* INTERVIEW QUESTIONS */}
            {user.role === UserRoleNames.SUPER_ADMIN ? (
              <ApplicationGeneratedInterviewQuestions
                ai_sbca_summary={application.ai_sbca_summary}
                ai_resume_summary={application.ai_resume_summary}
              />
            ) : null}

            {/* EMPLOYMENT HISTORY */}
            <ApplicationExtraInformation
              form_name={application.form_name}
              extra_info={application.applicant.custom_values}
              is_hidden={application.is_hidden}
              isOpenByDefault={defaultOpenAccordion === "employment-history"}
            />
          </div>

          <div className="col-12 col-lg-4">
            {/* ADMIN ACTIONS */}
            {user.role === UserRoleNames.SUPER_ADMIN ? (
              <ApplicationsDetailsAdminActions application={application} />
            ) : null}

            {/* SCHEDULE APPOINTMENTS */}
            <PermissionCheckComponentWrapper permissions={["applicant_appointments_create"]}>
              <Card modifierClass={`card--rounded ${application.is_hidden ? "card--hidden" : ""}`}>
                <h2 className="fw--semibold mb--20" ref={appointmentsComponentRef}>
                  Schedule Interviews
                </h2>

                {!showScheduleAppointmentsMenu ? (
                  <div className="d-flex justify-content-center">
                    <Button
                      modifierClass="btn--fluid btn--fluid--md btn--primary btn--pill applications-details__schedule-appointments__btn"
                      onClick={() => setShowScheduleAppointmentsMenu(true)}
                    >
                      Schedule Interview
                    </Button>
                  </div>
                ) : null}

                {showScheduleAppointmentsMenu ? (
                  <ScheduleMeetingMenu
                    applicationID={parseInt(applicationID!)}
                    meeting_link={application.meeting_link ?? application.schedule_meeting}
                    handleCloseMenu={() => setShowScheduleAppointmentsMenu(false)}
                  />
                ) : null}
              </Card>
            </PermissionCheckComponentWrapper>

            {/* APPOINTMENTS */}
            <PermissionCheckComponentWrapper permissions={["applicant_appointments_view"]}>
              <Card modifierClass={`card--rounded ${application.is_hidden ? "card--hidden" : ""}`}>
                <h2 className="d-flex align-items-center fw--semibold mb--20">
                  Interviews
                  {applicationIsRefetching && <Loader size="sm" modifierWrapper="ml--10" />}
                </h2>

                {application && application.appointments.length > 0 ? (
                  application.appointments.map((appointment: AppointmentResponseFields) => (
                    <AppointmentsListItem
                      key={appointment.id}
                      details={{ ...appointment, applicationID }}
                    />
                  ))
                ) : (
                  <h6>No scheduled interviews.</h6>
                )}
              </Card>
            </PermissionCheckComponentWrapper>

            {/* COMMENTS */}
            <Card modifierClass={`card--rounded ${application.is_hidden ? "card--hidden" : ""}`}>
              <h2 className="d-flex align-items-center fw--semibold mb--20">Comments</h2>

              {application && application.comments.length > 0 ? (
                <AnimatePresence initial={false}>
                  {application.comments.map((comment: ApplicationCommentsFields, index: number) => (
                    <ApplicationComment
                      key={`applications-details-comment-${index}`}
                      index={index}
                      applicationID={applicationID}
                      comment={comment}
                      handleCommentEdit={handleCommentEdit}
                    />
                  ))}
                </AnimatePresence>
              ) : (
                <h6 className="mb--10">No comments available.</h6>
              )}

              {!showCommentsCreateMenu && (
                <div className="d-flex justify-content-end mt--20">
                  <Button
                    modifierClass="btn--fluid btn--pill btn--transparent btn--border btn--border--primary mb--20"
                    onClick={() => setShowCommentsCreateMenu(true)}
                  >
                    Add Comment
                  </Button>
                </div>
              )}

              {showCommentsEditMenu && (
                <ApplicationCommentMenu
                  mode="edit"
                  applicationID={applicationID}
                  comments={application.comments}
                  commentDetails={commentDetails}
                  handleCloseMenu={() => setShowCommentsEditMenu(false)}
                />
              )}

              {showCommentsCreateMenu && (
                <ApplicationCommentMenu
                  mode="create"
                  applicationID={applicationID}
                  comments={application.comments}
                  commentDetails={null}
                  handleCloseMenu={() => setShowCommentsCreateMenu(false)}
                />
              )}
            </Card>

            {/* APPLICANT HISTORY */}
            <ApplicationHistoryWrapper
              registration_date={application.registration_date}
              is_hidden={application.is_hidden}
            />
          </div>
        </div>

        <AnimatePresence>
          {emailApplicationModal && application && (
            <ApplicationEmailModal
              application_id={applicationID}
              applicant_name={handleFullnameCombination(application.applicant)}
              handleCloseModal={() => setEmailApplicationModal(false)}
            />
          )}
        </AnimatePresence>
      </div>
    );
  }
};

export default ApplicationsDetails;
