// Hooks & Utilities
import { useEffect, useMemo, useState } from "react";
import { useAuth } from "../../../providers/auth-context";
import { format } from "date-fns";
import handleEncodeEmailTemplate from "../../../utilities/strings/handleEncodeEmailTemplate";

// Interfaces & Constants
import {
  ApplicationEmailTemplateDetails,
  AvailableEmailTemplates,
  EmailTemplateDetailsModel,
  EmailTemplateModel,
} from "../interfaces";
import { DROPDOWN_TEMPLATE_OPTIONS, EMAIL_TEMPLATES } from "../../../templates/emails";

// Components
import Button from "../../Button/Button";
import Dropdown from "../../Dropdown/Dropdown";
import Modal from "../../Modal/Modal";
import { toast } from "react-toastify";
import Alert from "../../Alert/Alert";

const ApplicationEmailTemplatesModal = ({
  details,
  handleCloseModal,
}: {
  details: ApplicationEmailTemplateDetails;
  handleCloseModal: () => void;
}) => {
  const { user } = useAuth();

  // Currently active template name & object
  const [selectedTemplateName, setSelectedTemplateName] =
    useState<AvailableEmailTemplates>("blank-email");
  const [selectedTemplateData, setSelectedTemplateData] = useState<EmailTemplateModel | null>(null);

  useEffect(() => {
    const foundTemplate = EMAIL_TEMPLATES.find(template => template.name === selectedTemplateName);

    setSelectedTemplateData(foundTemplate || null);
  }, [selectedTemplateName]);

  /*===========================
  MAPPED TEMPLATE DATA
  ============================*/
  const [templateDetails, setTemplateDetails] = useState<EmailTemplateDetailsModel | null>();

  useEffect(() => {
    setTemplateDetails({
      applicantEmail: details.applicantEmail,
      applicantName: details.applicantName,
      senderName: `${user.first_name} ${user.last_name}`,
      applicationDate: format(new Date(details.applicationDate), "MM/dd/yyyy"),
      applicationPosition: details.applicationPosition,
      senderCompany: user.active_company.name,
      applicantLocation: details.applicantLocation,
    });
  }, [details, user]);

  /*==========================================================
  TEMPLATE VARIANTS
  
  The handler is used to generate a variant that is ready 
  to be "copied" and a variant ready to be sent to a mail 
  client from the selected HTML Email Template.
  For the mail client version we replace all <p>, <br /> and 
  styled <p> tags with the encoded character for new line and 
  for the "text copy" version we use the bare text content 
  of the generated HTML template, both variants generated
  with the 'handleEncodeEmailTemplate' utility function.
  ==========================================================*/
  const generatedTemplateText = useMemo(() => {
    if (!selectedTemplateData || !templateDetails)
      return { TEMPLATE_TEXT_CONTENT: "", TEMPLATE_EMAIL_ENCODED: "" };

    const data = selectedTemplateData.html(templateDetails);

    // Call the utility function to apply proper encoding for email client & text content for 'Copy Template' functionality
    const { TEMPLATE_TEXT_CONTENT, TEMPLATE_EMAIL_ENCODED } = handleEncodeEmailTemplate(data);

    return { TEMPLATE_TEXT_CONTENT, TEMPLATE_EMAIL_ENCODED };
  }, [selectedTemplateData, details, user]);

  const handleCopyTemplate = async () => {
    try {
      await navigator.clipboard.writeText(generatedTemplateText.TEMPLATE_TEXT_CONTENT);
      toast.info("Email template copied!");
    } catch (error) {
      toast.error("Failed copying email template!");
    }
  };

  return (
    <>
      <Modal
        title=""
        text=""
        handleCloseModal={handleCloseModal}
        modifierClass="application-email-modal modal--fixated txt--left"
        testID="modal-send-email-template"
      >
        <div className="d-flex flex-column flex-md-row justify-content-between align-items-center mr--20 mr--md--0 mb--20">
          <h2 className="mb--md--10">
            <strong>Send Email</strong>
          </h2>
          <div className="d-flex flex-column flex-md-row align-items-center txt--lg">
            <span className="d-flex align-items-center mb--md--10 pr--10">
              <strong className="white-space--no-wrap">Choose Template:</strong>
            </span>
            <Dropdown
              items={DROPDOWN_TEMPLATE_OPTIONS}
              handleItemSelected={item =>
                setSelectedTemplateName(item.value as AvailableEmailTemplates)
              }
              title="Select Email Template"
              preselectedItemValue={selectedTemplateName}
              size="xl"
            />
          </div>
        </div>
        <h6 className="mb--10">
          <strong>To: </strong> {details.applicantEmail}
        </h6>
        <h6 className="mb--10">
          <strong>Subject: </strong>
          {selectedTemplateName !== "blank-email" &&
          selectedTemplateData &&
          templateDetails &&
          selectedTemplateData.subject(templateDetails)
            ? selectedTemplateData.subject(templateDetails)
            : "N/A"}
        </h6>
        {selectedTemplateName !== "blank-email" ? (
          <div
            className="application-email-modal__preview"
            dangerouslySetInnerHTML={{
              __html:
                selectedTemplateData && templateDetails
                  ? selectedTemplateData.html(templateDetails)
                  : "N/A",
            }}
          ></div>
        ) : (
          <div
            style={{
              width: "100%",
              minHeight: "100px",
              textAlign: "center",
              marginTop: 50,
            }}
          ></div>
        )}
        {generatedTemplateText.TEMPLATE_EMAIL_ENCODED.length > 2000 ? (
          <Alert
            text={
              <span className="txt--lg">
                Due to browser limitations, larger templates might not correctly open the email
                client. It is recommended to use the <strong>Copy Template</strong> button and paste
                the content directly into your email client.
              </span>
            }
            type="info"
            modifierClass="alert--txt-black"
          />
        ) : null}
        <div className="d-flex flex-column-reverse flex-md-row justify-content-md-between align-items-center">
          <Button
            type="button"
            modifierClass="btn--fluid btn--fluid--md btn--primary--light"
            onClick={handleCloseModal}
          >
            Cancel
          </Button>
          <div>
            <Button
              modifierClass="btn--fluid btn--fluid--md btn--secondary mr--10 mb--md--10"
              isDisabled={!selectedTemplateName || selectedTemplateName === "blank-email"}
              onClick={() => handleCopyTemplate()}
            >
              Copy Template
            </Button>
            <a
              className="btn btn--fluid btn--fluid--md btn--primary ml--10 ml--md--0 mb--md--10"
              href={
                generatedTemplateText.TEMPLATE_EMAIL_ENCODED.length < 2000
                  ? `mailto:${details.applicantEmail}?${
                      selectedTemplateData && templateDetails
                        ? `subject=${selectedTemplateData.subject(templateDetails)}`
                        : ""
                    }&body=${generatedTemplateText.TEMPLATE_EMAIL_ENCODED}`
                  : undefined
              }
              aria-disabled={
                generatedTemplateText.TEMPLATE_EMAIL_ENCODED.length > 2000 ? "true" : "false"
              }
            >
              Send Email
            </a>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ApplicationEmailTemplatesModal;
