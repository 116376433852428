import { useEffect } from "react";
import { ApplicationDetailsFields } from "../../api/Applications/interfaces";
import { useAuth } from "../../providers/auth-context";

const VerifiedFirst = ({ details }: { details: ApplicationDetailsFields }) => {
  const { user } = useAuth();

  // Load the Verified First widget script
  useEffect(() => {
    const script = document.createElement("script");

    script.src = "https://widget.verifiedfirst.com/Browser-Extension/src/app/widget.js";
    script.type = "text/javascript";
    script.async = true;

    document.body.appendChild(script);

    // Clean up - remove the script on unmount
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
      <div id="verified-first">
        <div className="hidden">
          <input type="hidden" id="individual-id" value={details.id} />
          <input type="hidden" id="individual-first-name" value={details.applicant.first_name} />
          <input type="hidden" id="individual-last-name" value={details.applicant.last_name} />
          <input
            type="hidden"
            id="individual-middle-name"
            value={details.applicant.middle_name || ""}
          />
          <input type="hidden" id="individual-email" value={details.applicant.email} />
          <input type="hidden" id="individual-mobile-phone" value={details.applicant.phone} />
          <input type="hidden" id="individual-address-line-1" value={details.applicant.address} />
          <input type="hidden" id="individual-address-city" value={details.applicant.city} />
          <input type="hidden" id="individual-address-state" value={details.applicant.state} />
          <input
            type="hidden"
            id="individual-address-postal-code"
            value={details.applicant.zip_code}
          />
          <input type="hidden" id="individual-address-postal-country" value="US" />

          <input type="hidden" id="user-firstname" value={user.first_name} />
          <input type="hidden" id="user-lastname" value={user.last_name} />
          <input type="hidden" id="user-email" value={user.email} />
          <input type="hidden" id="user-phone" value={user.user_data?.phone1 ?? ""} />
          <input type="hidden" id="user-company" value={user.active_company.name} />
          <input type="hidden" id="user-misc1" value={user.id} />
        </div>
      </div>

      <div id="vf-widget-button-wrap"></div>
    </>
  );
};

export default VerifiedFirst;
