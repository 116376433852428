// Components
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";

// Context Provider
import { ChatContextWrapper } from "../components/Chat/ChatWrapper/ChatContextWrapper";

interface LayoutAuthenticatedProps {
  children: React.ReactNode;
  isFullHeader?: boolean;
}

const LayoutAuthenticated = ({ isFullHeader = true, children }: LayoutAuthenticatedProps) => {
  return (
    <ChatContextWrapper>
      <Header isFullHeader={isFullHeader} />
      <main>{children}</main>
      <Footer />
    </ChatContextWrapper>
  );
};

export default LayoutAuthenticated;
