import { ComponentProps, ReactNode, useEffect, useState } from "react";
import AnimateHeight from "react-animate-height";

// Assets
import { FaChevronDown as AccordionToggleIcon } from "react-icons/fa";

interface AccordionProps {
  title: string;
  children: ReactNode;
  defaultHeight?: number;
  duration?: number;
  disabled?: boolean;
  modifierClass?: string;
  isOpenByDefault?: boolean;
}

function Accordion({
  title,
  defaultHeight = 0,
  duration = 300,
  children,
  disabled = false,
  modifierClass = "",
  isOpenByDefault = false,
  ...props
}: AccordionProps & ComponentProps<"div">) {
  const [isOpen, setIsOpen] = useState<boolean>(isOpenByDefault);
  const [height, setHeight] = useState<number | "auto">(defaultHeight);

  const handleToggleAccordion = () => {
    if (disabled) return;
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    setIsOpen(isOpenByDefault);
  }, [isOpenByDefault]);

  useEffect(() => {
    setHeight(isOpen ? "auto" : defaultHeight);
  }, [isOpen]);

  return (
    <div className={`accordion ${modifierClass}`} {...props}>
      <div className="accordion__header" onClick={handleToggleAccordion}>
        <h2 className="fw--semibold">{title}</h2>
        <AccordionToggleIcon
          className={`accordion__chevron ${isOpen ? "accordion__chevron--active" : ""}`}
        />
      </div>

      <AnimateHeight id={props.id} duration={duration} height={height} animateOpacity>
        <div className="accordion__body">
          <hr />
          {children}
        </div>
      </AnimateHeight>
    </div>
  );
}

export default Accordion;
