// Hooks & Utilities
import { useMemo } from "react";
import { createColumnHelper } from "@tanstack/react-table";
import { Link } from "react-router-dom";
import { useLogsSMSGetAll } from "../../api/Logs/Logs";
import { format } from "date-fns";

// Components
import TableSkeletonPlaceholder from "../../components/SkeletonPlaceholders/TableSkeletonPlaceholder";
import Table from "../../components/Table/Table";

// Interfaces
import { SMSLogsProps, SMSLogsTableColumns } from "./interfaces";
import { handleDateAsTimestamp } from "../../utilities/dates/handleDateAsTimestamp";

const SMSLogs = ({ searchedValue, handleLogsCount }: SMSLogsProps) => {
  /*=========================
    GENERATE TABLE COLUMNS
  ==========================*/
  const COLUMN_HELPER = createColumnHelper<SMSLogsTableColumns>();

  const SMS_TABLE_COLUMNS = [
    COLUMN_HELPER.accessor("id", {
      header: () => <span>ID</span>,
      cell: data => <span>{data.getValue() != null ? data.getValue() : "N/A"}</span>,
      size: 30,
    }),
    COLUMN_HELPER.accessor("sent", {
      header: () => <span>Sent</span>,
      cell: data => (
        <span>
          {data.getValue()
            ? format(handleDateAsTimestamp(data.getValue() as number), "MM/dd/yyyy HH:mm:ss")
            : "N/A"}
        </span>
      ),
      size: 110,
    }),
    COLUMN_HELPER.accessor("status", {
      header: () => <span>Status</span>,
      cell: data => <span className="txt--capitalize">{data.getValue() || "N/A"}</span>,
    }),
    COLUMN_HELPER.accessor("from", {
      header: () => <span>From</span>,
      cell: data => (
        <Link
          to={`/notification-logs/sms/details/${data.row.original.id}`}
          className="btn--text--secondary"
        >
          <span>{data.getValue() || "N/A"}</span>
        </Link>
      ),
    }),
    COLUMN_HELPER.accessor("to", {
      header: () => <span>To</span>,
      cell: data => (
        <Link
          to={`/notification-logs/sms/details/${data.row.original.id}`}
          className="btn--text--secondary"
        >
          <span>{data.getValue() || "N/A"}</span>
        </Link>
      ),
    }),
    COLUMN_HELPER.accessor("action", {
      header: () => <span>Action</span>,
      cell: data => (
        <Link
          to={`/notification-logs/sms/details/${data.row.original.id}`}
          className="btn--text--secondary"
        >
          <span>{data.getValue() || "N/A"}</span>
        </Link>
      ),
      size: 250,
    }),
  ];

  /*======================
    API DATA
  =======================*/
  const { data, isLoading, isFetching } = useLogsSMSGetAll(searchedValue);

  /*======================
    TABLE DATA
  =======================*/
  const SMS_LOGS = useMemo(() => {
    // Exit function if there's no available data to work with
    if (!data || !data.smsMessages.length || isLoading) {
      // Trigger callback to update the shown logs count
      handleLogsCount(null);
      return [];
    }

    // Sort the listed data based on the ID value
    const smsLogsDataCopy = [...data.smsMessages].sort((logA, logB) => {
      return logA.id > logB.id ? -1 : 1;
    });

    // Trigger callback to update the shown logs count
    handleLogsCount({ shownCount: data.smsMessages.length, totalCount: data.total });

    return smsLogsDataCopy;
  }, [data]);

  return (
    <>
      {isLoading ? (
        <TableSkeletonPlaceholder modifierClass="table-wrapper table-wrapper--no-shadow table-wrapper--no-overflow" />
      ) : (
        <Table
          data={SMS_LOGS}
          noDataMessage="No SMS logs data to show"
          columns={SMS_TABLE_COLUMNS}
          isRefetching={isFetching}
          paginationPageSize={100}
          modifierClass="table-wrapper--no-shadow"
        />
      )}
    </>
  );
};

export default SMSLogs;
