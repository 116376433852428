import {
  ApplicantAIResumeSummary,
  ApplicantAISbcaSummary,
} from "../../api/Applications/interfaces";
import Accordion from "../../components/Accordion/Accordion";
import MarkdownWrapper from "../../components/Markdown/Markdown";

interface ApplicationGeneratedInterviewQuestionsProps {
  ai_sbca_summary: ApplicantAISbcaSummary;
  ai_resume_summary: ApplicantAIResumeSummary;
}

function ApplicationGeneratedInterviewQuestions({
  ai_sbca_summary,
  ai_resume_summary,
}: ApplicationGeneratedInterviewQuestionsProps) {
  // Render fallback content if there are neither SBCA nor Resume questions
  if (!ai_sbca_summary.sbca_interview_questions && !ai_resume_summary.resume_interview_questions) {
    return (
      <Accordion
        id="accordion-interview-questions"
        title="Interview Questions"
        modifierClass="accordion--rounded"
      >
        <p className="accordion__empty-message">No interview questions at this time</p>
      </Accordion>
    );
  }

  return (
    <Accordion
      id="accordion-interview-questions"
      title="Interview Questions"
      modifierClass="accordion--rounded"
    >
      <h5 className="mb--10 fw--semibold">Resume Questions</h5>
      {ai_resume_summary.resume_interview_questions ? (
        <MarkdownWrapper markdown={ai_resume_summary.resume_interview_questions} />
      ) : (
        <p className="white-space--pre-line mb--20">No resume questions available.</p>
      )}

      <h5 className="mb--10 fw--semibold">SBCA Questions</h5>
      {ai_sbca_summary.sbca_interview_questions ? (
        <MarkdownWrapper markdown={ai_sbca_summary.sbca_interview_questions} />
      ) : (
        <p className="white-space--pre-line mb--10">No SBCA questions available.</p>
      )}
    </Accordion>
  );
}

export default ApplicationGeneratedInterviewQuestions;
