// Hooks & Utilities
import { useEffect, useMemo, useRef, useState } from "react";
import { usePreventBodyScroll } from "../../../hooks/usePreventBodyScroll";
import { useAuth } from "../../../providers/auth-context";
import { useApplicationGetResume } from "../../../api/Applications/Applications";
import useWindowResize from "../../../hooks/useWindowResize";
import useErrorReporting from "../../../hooks/useErrorReporting";
import fileDownloadHandler from "../../../api/fileDownloadHandler";

// Components
import { motion } from "framer-motion";
import ApplicationArchive from "../Buckets/ApplicationArchive";
import ApplicationFavorite from "../Buckets/ApplicationFavorite";
import ApplicationSBCA from "../ApplicationSBCA";
import Button from "../../Button/Button";
import Loader from "../../Loader/Loader";
import ModalBackdrop from "../../Modal/ModalBackdrop";
import PermissionCheckComponentWrapper from "../../Wrappers/PermissionCheckComponentWrapper";
import { Link } from "react-router-dom";

// PDF Package
import { Document, Page, pdfjs } from "react-pdf";
import pdfJSWorkerURL from "pdfjs-dist/build/pdf.worker.min?url";

// Register the PDF Worker
pdfjs.GlobalWorkerOptions.workerSrc = pdfJSWorkerURL;

// Assets
import { MdClose as CloseIcon } from "react-icons/md";
import InfoIcon from "../../../assets/images/icons/info-icon.svg?react";
import { BsChevronLeft as PreviousButton, BsChevronRight as NextButton } from "react-icons/bs";

// Interfaces
import { ApplicationDetailsMappedFields, ApplicationModalResumePreviewProps } from "../interfaces";

const ApplicationModalResumePreview = ({
  id,
  allPaginatedApplications,
  allApplications,
  handleCloseModal,
}: ApplicationModalResumePreviewProps) => {
  const errorReporting = useErrorReporting();

  /*==================================
    TARGET THE APPLICATION BASED ON
    THE CURRENTLY SELECTED "ID" AND
    EXTRACT THAT APPLICATION'S DETAILS
  ===================================*/
  const [selectedApplicationID, setSelectedApplicationID] = useState<number>(id);

  const handleApplicationSelection = (applicationID: number | null) => {
    if (applicationID == null) return;
    setSelectedApplicationID(applicationID);
  };

  // The details of the filtered targeted application
  // before it was moved to Archived or Favorite bucket by the user
  const [applicationDetails, setApplicationDetails] =
    useState<ApplicationDetailsMappedFields | null>(null);

  const TARGETED_APPLICATION = useMemo(() => {
    // Find the targeted application from the list of the "Filtered" applications with resume
    const fillteredApplicationsWithResume = allPaginatedApplications.filter(application => {
      return application.resume;
    });
    const filteredTargetedApplication = fillteredApplicationsWithResume.find(application => {
      return application.id === selectedApplicationID;
    });

    // Find the targeted application from the list of "All" existing applications with resume
    const allApplicationsWithResume = allApplications.filter(application => application.resume);
    const targetedApplication = allApplicationsWithResume.find(application => {
      return application.id === selectedApplicationID;
    });

    if (filteredTargetedApplication) {
      setApplicationDetails(filteredTargetedApplication);
      return filteredTargetedApplication;
    } else if (targetedApplication) {
      setApplicationDetails(null);
      return {
        ...targetedApplication,
        idOfNextApplicationWithResume: applicationDetails?.idOfNextApplicationWithResume ?? null,
        idOfPreviousApplicationWithResume:
          applicationDetails?.idOfPreviousApplicationWithResume ?? null,
      };
    }

    // If the targeted application cannot be found neither in
    // the paginated applications list or the all available paginations list,
    // then return null value which will close the component itself
    return null;
  }, [selectedApplicationID, allPaginatedApplications, allApplications]);

  /*==================================
    FETCH THE APPLICATION'S RESUME
  ===================================*/
  const applicationResume = useApplicationGetResume(TARGETED_APPLICATION?.id);

  /*==================================
    COUNT THE TOTAL NUMBER OF PAGES
    IN THE PROVIDED RESUME PDF
  ===================================*/
  const [pdfTotalPages, setPdfTotalPages] = useState<number>(0);

  const handlePDFViewerLoadSuccess = ({ numPages }: any) => setPdfTotalPages(numPages);

  /*==================================
    HANDLE PDF RELATED ERRORS
  ===================================*/
  const [pdfHasError, setPdfHasError] = useState<boolean>(false);

  const handlePDFViewerError = () => setPdfHasError(true);

  /*================================
    DISPLAY CURRENT PDF'S PAGE
    IN THE UI BASED ON USER'S SCROLL
  ================================*/
  const [currentPage, setCurrentPage] = useState<number>(1);
  const pdfContainerRef = useRef<HTMLDivElement | null>(null);

  // If the container ref exists in the DOM, and there's no error related to the PDF,
  // attch scroll event listeners so we can update the current page based on user's scroll
  if (pdfContainerRef && pdfContainerRef.current && !pdfHasError) {
    // This is the header element's height within the PDF viewer's container,
    // where the current and total number of pages are displayed,
    const pdfContainerHeaderElementHeight: number = 50;

    pdfContainerRef.current.addEventListener("scroll", function (this) {
      const scrolledTopDistance: number = this.scrollTop;

      this.querySelectorAll(".react-pdf__Page").forEach((pdfPage: Element, pageIndex: number) => {
        // Typecast the pdf page element to the correct type so we can have access to "offsetTop" property
        const pageAsHTMLElement = pdfPage as HTMLElement;
        const pageOffsetTop = pageAsHTMLElement.offsetTop - pdfContainerHeaderElementHeight;

        // If the user has scrolled "TO" or "PAST" the page's top offset value,
        // then we increment the state value for the current page shown in the UI
        if (scrolledTopDistance >= pageOffsetTop && currentPage <= pageIndex + 1) {
          setCurrentPage(pageIndex + 1);
        }
      });
    });
  }

  // Prevent the body of the page from scrolling when
  // the modal component is displayed in the UI.
  usePreventBodyScroll();

  /*=========================
    DOWNLOAD THE 
    APPLICATION'S RESUME
  ==========================*/
  const { user } = useAuth();
  const handleResumeDownload = async () => {
    try {
      await fileDownloadHandler(
        `company/${user.active_company.slug}/applications/${selectedApplicationID}/resume/download`,
      );
    } catch (error) {
      errorReporting("Failed downloading application resume", error, {
        application_id: selectedApplicationID,
        company_slug: user.active_company.slug,
      });
    }
  };

  /*=======================
    CLOSE ON "ESCAPE" KEY
  ========================*/
  const modalRef = useRef<HTMLDivElement>(null);

  // Attach and remove keyboard event listeners as the currently
  // selected application ID is changed, so we can make sure that we always
  // call the function for closing the modal with the latest state
  useEffect(() => {
    window.addEventListener("keyup", handleCloseOnEscape, false);
    return () => window.removeEventListener("keyup", handleCloseOnEscape, false);
  }, [selectedApplicationID]);

  const handleCloseOnEscape = (event: KeyboardEvent) => {
    event.preventDefault();
    if (event.key === "Escape") handleCloseModal(selectedApplicationID);
  };

  /*=======================
    PASSWORD PROTECTED
  ========================*/
  const [pdfIsPasswordProtected, setPdfIsPasswordProtected] = useState<boolean>(false);

  const handleOnPassword = (callback: any, reason: any) => {
    function callbackProxy(password: string) {
      if (!password) {
        setPdfIsPasswordProtected(true);
        callback(password);
      }
    }

    switch (reason) {
      // This corresponds to NEEDS PASSWORD
      case 1:
        callbackProxy("");
        break;
      // This corresponds to INVALID
      case 2:
        callbackProxy("");
        break;
    }
  };

  // Reset the "PDF Password protected" error state when the application is  changed
  useEffect(() => {
    setPdfIsPasswordProtected(false);
    setPdfHasError(false);
  }, [selectedApplicationID]);

  /*=======================
    AUTOMATICALLY CLOSE 
    RESUME PREVIEW MODAL
    
    If the targeted application cannot be found
    then automatically close the modal
  ========================*/
  if (!TARGETED_APPLICATION) {
    handleCloseModal();
    return null;
  }

  /*========================
    PDF Scaling based on
    device windows size.
  ==========================*/
  const [windowWidth] = useWindowResize();
  const [pdfScaling, setPdfScaling] = useState<number>(1.5);

  useEffect(() => {
    // For desktop
    if (windowWidth >= 991 && pdfScaling !== 1.5) setPdfScaling(1.5);

    // For tablet devices
    if (windowWidth < 991 && pdfScaling !== 1) setPdfScaling(1);

    // For mobile devices
    if (windowWidth < 575 && pdfScaling !== 0.7) setPdfScaling(0.7);
  }, [windowWidth]);

  return (
    <div className="application-resume-modal__wrapper" ref={modalRef} tabIndex={0}>
      <ModalBackdrop handleCloseModal={() => handleCloseModal(selectedApplicationID)} />

      <motion.div
        className={`application-resume-modal ${
          applicationResume.isError || pdfHasError ? "application-resume-modal--error" : ""
        }`}
        key="framer-modal"
        initial={{ opacity: 0, transform: "translateX(-50%) translateY(-150px)" }}
        animate={{
          opacity: 1,
          transform: "translateX(-50%) translateY(-50%)",
          transition: { delay: 0.1 },
        }}
        exit={{ opacity: 0, transform: "translateX(-50%) translateY(-150px)" }}
        transition={{ duration: 0.4 }}
        data-testid="component:modal-resume-preview"
      >
        <div className="application-resume-modal__header">
          <div
            className={`application-resume-modal__header__navigation ${
              !TARGETED_APPLICATION.idOfPreviousApplicationWithResume ||
              !TARGETED_APPLICATION.idOfNextApplicationWithResume
                ? "mr--140"
                : "mr--40"
            }`}
          >
            {TARGETED_APPLICATION.idOfPreviousApplicationWithResume && (
              <span
                className="application-resume-modal__movement-button application-resume-modal__movement-button--previous"
                onClick={() =>
                  handleApplicationSelection(TARGETED_APPLICATION.idOfPreviousApplicationWithResume)
                }
              >
                <PreviousButton />
                Previous
              </span>
            )}
            <div className="application-resume-modal__header__buckets">
              <PermissionCheckComponentWrapper permissions={["buckets_view"]}>
                <ApplicationArchive
                  id={TARGETED_APPLICATION.id}
                  currentBuckets={TARGETED_APPLICATION.buckets}
                  text="Archive"
                  name={TARGETED_APPLICATION.name}
                />

                <ApplicationFavorite
                  id={TARGETED_APPLICATION.id}
                  currentBuckets={TARGETED_APPLICATION.buckets}
                  text="Favorite"
                  name={TARGETED_APPLICATION.name}
                />
              </PermissionCheckComponentWrapper>
            </div>
            {TARGETED_APPLICATION.idOfNextApplicationWithResume && (
              <span
                className={`application-resume-modal__movement-button application-resume-modal__movement-button--next`}
                onClick={() =>
                  handleApplicationSelection(TARGETED_APPLICATION.idOfNextApplicationWithResume)
                }
              >
                Next <NextButton />
              </span>
            )}
          </div>
          <div className="application-resume-modal__header__details">
            <h5
              className="application-resume-modal__header__details__name"
              title={TARGETED_APPLICATION.name}
            >
              <strong>{TARGETED_APPLICATION.name}</strong>
            </h5>

            <Link to={`/applications/${selectedApplicationID}/`}>View Details</Link>
            <div className="application-resume-modal__sbca">
              <ApplicationSBCA
                id={TARGETED_APPLICATION.id}
                name={TARGETED_APPLICATION.name}
                email={TARGETED_APPLICATION.email}
                sbca={TARGETED_APPLICATION.sbca}
                sbca_report_identifier={TARGETED_APPLICATION.sbca_report_identifier}
                sbca_request_date={TARGETED_APPLICATION.sbca_request_date}
              />
            </div>
          </div>

          {/* Bucket action buttons for responsive */}
          <div className="application-resume-modal__movement-button__responsive">
            {TARGETED_APPLICATION.idOfPreviousApplicationWithResume && (
              <span
                className="application-resume-modal__movement-button application-resume-modal__movement-button--previous"
                onClick={() =>
                  handleApplicationSelection(TARGETED_APPLICATION.idOfPreviousApplicationWithResume)
                }
              >
                <PreviousButton />
                Previous
              </span>
            )}

            {TARGETED_APPLICATION.idOfNextApplicationWithResume && (
              <span
                className="application-resume-modal__movement-button application-resume-modal__movement-button--next"
                onClick={() =>
                  handleApplicationSelection(TARGETED_APPLICATION.idOfNextApplicationWithResume)
                }
              >
                Next <NextButton />
              </span>
            )}
          </div>

          <span
            className="application-resume-modal__close"
            onClick={() => handleCloseModal(selectedApplicationID)}
          >
            <CloseIcon />
          </span>
        </div>

        {pdfIsPasswordProtected ? (
          <PasswordProtectedPDFError />
        ) : applicationResume.isError || pdfHasError ? (
          <InvalidPDFError />
        ) : (
          <div className="application-resume-modal__pdf-container" ref={pdfContainerRef}>
            <div className="application-resume-modal__pdf-container__pages">
              {applicationResume.isFetching ? null : (
                <span>
                  Page {currentPage} of {pdfTotalPages} pages
                </span>
              )}
            </div>

            {applicationResume.isFetching ? (
              <Loader size="page" modifierWrapper="loader--page" />
            ) : (
              <Document
                file={applicationResume.data?.resume}
                className="application-resume-modal__previewer"
                loading={<Loader size="page" modifierWrapper="loader--page" />}
                onLoadSuccess={handlePDFViewerLoadSuccess}
                onLoadError={handlePDFViewerError}
                onPassword={handleOnPassword}
              >
                {Array.from(new Array(pdfTotalPages), (_el, index: number) => (
                  <Page key={`page_${index + 1}`} pageNumber={index + 1} scale={pdfScaling} />
                ))}
              </Document>
            )}
          </div>
        )}

        <div className="d-flex flex-column-reverse flex-md-row justify-content-md-end align-items-center">
          <Button
            modifierClass="btn--fluid btn--fluid--md btn--primary--light"
            onClick={() => handleCloseModal(selectedApplicationID)}
          >
            Close
          </Button>

          <Button
            modifierClass="btn--fluid btn--fluid--md btn--primary ml--10 ml--md--0 mb--md--10"
            isLoading={applicationResume.isFetching}
            isDisabled={applicationResume.isFetching || applicationResume.isError}
            onClick={handleResumeDownload}
          >
            Download
          </Button>
        </div>
      </motion.div>
    </div>
  );
};

// Error presentational component if there's no valid PDF provided
const InvalidPDFError = () => {
  return (
    <div className="application-resume-modal__pdf-error">
      <InfoIcon className="application-resume-modal__pdf-error-icon" />

      <div>
        <h6 className="mb--5">
          <strong>There was an issue previewing this resume:</strong>
        </h6>

        <p className="m--0">
          Please refresh your browser. If the resume will not display in preview mode, please
          download it from the applicant profile, or by clicking the download button below.
        </p>

        <p className="m--0">
          Need assistance? We are here to help. Please contact your Account Manager, or submit a
          help request here:{" "}
          <a
            href="https://firstchoicehiring.com/helpdesk"
            target="blank"
            className="txt--underline"
          >
            https://firstchoicehiring.com/helpdesk
          </a>
        </p>
      </div>
    </div>
  );
};

const PasswordProtectedPDFError = () => {
  return (
    <div className="application-resume-modal__pdf-error">
      <InfoIcon className="application-resume-modal__pdf-error-icon" />

      <div>
        <h6 className="mb--5">
          <strong>
            This uploaded resume is password protected and cannot be opened without it.
          </strong>
        </h6>

        <p className="m--0">
          Please inform the applicant that they uploaded a password protected resume which cannot be
          opened and that they should re-apply, this time providing a non-protected resume.
        </p>

        <br />

        <p className="m--0">
          Need assistance? We are here to help. Please contact your Account Manager, or submit a
          help request here:{" "}
          <a
            href="https://firstchoicehiring.com/helpdesk"
            target="blank"
            className="txt--underline"
          >
            https://firstchoicehiring.com/helpdesk
          </a>
        </p>
      </div>
    </div>
  );
};

export default ApplicationModalResumePreview;
