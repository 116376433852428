import { useSearchParams } from "react-router-dom";

/**
 *
 * Hook for extracting the query parameters from
 * the URL that the user visited
 *
 */
export function useExtractSearchParameters(): [Record<string, any>, Function] {
  const [searchParameters, setSearchParameters] = useSearchParams();
  const searchParametersObject = Object.fromEntries(new URLSearchParams(searchParameters));

  return [searchParametersObject, setSearchParameters];
}
